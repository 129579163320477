@use "~styles/constants" as *;

.series-position {
    &-title {
        display: flex;
        justify-content: space-between;
        padding: 20px;
        font-size: 1.2rem;
        font-weight: normal;
        line-height: 1.33;
        color: $marine;
    }

    &-buttons {
        display: flex;
        align-items: center;
        > div {
            border-radius: 4px;
            background-color: #ffffff;
            cursor: pointer;
            display: flex;
            font-size: 0.625rem;
            align-items: center;
            justify-content: center;
            padding: 3px 13px 1px 13px;
        }
        :not(:first-child) {
            margin-left: 7px;
        }
        &-active {
            border: solid 1px $cool-blue;
            color: $cool-blue;
        }
        &-inactive {
            border: solid 1px $very-light-pink;
            color: $brown-grey;
        }
    }

    &-content {
        &-vertical {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
        }
        &-horizontal {
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 20px;

            > div {
                width: 50%;
            }
        }
    }

    &-graph {
        padding: 0 20px;

        > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
        &-label {
            font-size: 0.6875rem;
            font-weight: normal;
            color: var(--greyish-brown);
            display: flex;
            align-items: center;
        }
    }

    &-subtitles {
        &-vertical {
            .series-position-subtitle {
                &:not(:last-child) {
                    border-bottom: solid 1px #e3e3e3;
                }
            }
        }
        &-horizontal {
            .series-position-subtitle {
                display: flex;
                justify-content: center;
                flex-direction: column;
                width: max-content;
            }

            .divider {
                height: 35px;
                width: 1px;
                border-right: 1px solid #e3e3e3;
            }
        }
    }

    &-label {
        font-size: 0.875rem;
        font-weight: 600;
        margin-left: 13px;

        &-one {
            color: $marine;
            font-size: 0.75rem;
            font-weight: normal;
        }
        &-two {
            color: $greyish-brown;
            font-size: 0.75rem;
            font-weight: 500;
            line-height: 2;
        }
        &-three {
            color: $greyish-brown;
            font-size: 0.625rem;
            font-weight: normal;
            width: max-content;
        }
        &-four {
            color: $greyish-brown;
            font-size: 0.875rem;
            font-weight: 300;
        }
    }

    &-value {
        font-size: 0.875rem;
        font-weight: 300;
        color: #000000;

        &-one {
            color: $greyish-brown;
            font-size: 0.875rem;
            font-weight: 300;
            line-height: 1.43;
        }
        &-two {
            color: $marine;
            font-size: 1rem;
            font-weight: normal;
        }
        &-three {
            color: $marine;
            font-size: 0.875rem;
            font-weight: normal;
        }
        &-four {
            color: $greyish-brown;
            font-size: 0.875rem;
            font-weight: bold;
            line-height: 1.71;
        }
        &-five {
            font-size: 1rem;
            font-weight: normal;
            color: $dark-sky-blue;
        }
        &-focus {
            font-size: 1rem;
            color: $marine;
        }
    }

    &-box {
        height: 10px;
        margin-right: 7px;
        width: 10px;
    }

    &-item {
        display: flex;
        align-items: center;
    }

    &-img {
        content: $svg_icon_graph;
    }

    &-bar {
        width: 100%;
        height: 6px;
        border-radius: 20px;
        box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.21);
        background-color: #e3e3e3;

        &-progress {
            border-radius: 20px;
            height: 100%;
            margin: 0;
        }
    }

    &-content-card {
        padding: 10px 20px 20px;
        &-header {
            border-bottom: solid 1px #e3e3e3;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            padding: 10px 10px 20px;
        }
        &-list {
            padding: 10px 10px 30px;
            &-item {
                display: flex;
                line-height: 30px;
                justify-content: space-between;
                align-items: baseline;
                &:first-child {
                    padding-top: 20px;
                }
            }
        }
    }

    &-dashed-line {
        border-bottom: dashed 1px $greyish-brown;
        display: flex;
        flex-grow: 1;
        margin: 0 10px;
    }

    &-one {
        .series-position {
            &-subtitles-vertical {
                padding: 20px 0;
            }
            &-subtitle {
                padding: 10px 20px;
            }
            &-label-one {
                display: flex;
                align-items: baseline;
            }
            &-value {
                margin-bottom: -5px;
                &-focus {
                    color: $dark-blue-grey;
                    margin-bottom: -5px;
                }
            }
        }
    }

    &-two {
        .series-position {
            &-graph {
                padding-left: 0;
                width: 55%;
            }
            &-content-horizontal {
                padding-top: 0;
            }
            &-subtitles-vertical {
                margin-top: -32.5px;
                padding: 0 15px;
            }
            &-subtitle {
                padding: 7px 0;
            }
        }
    }

    &-three {
        .series-position {
            &-graph {
                padding-left: 0;
            }
            &-subtitle {
                padding-left: 1.5rem;
            }
            &-card {
                display: flex;
                justify-content: flex-end;
            }
        }
    }

    &-four {
        .series-position {
            &-content-vertical {
                padding-bottom: 20px;
            }
            &-graph {
                padding-left: 0;
            }
        }
    }

    &-error {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;

        div > button {
            max-width: none !important;
        }
    }

    &--chart > div {
        right: 20px;
    }

    .tooltip--block {
        min-width: max-content;
        hr {
            margin: 5px 0;
        }
        &-two {
            display: flex;
            flex-direction: column;
        }
        &-line {
            display: flex;
            align-items: center;
            width: max-content;
        }
        &-color {
            display: inline-block;
            height: 10px;
            margin-right: 5px;
            width: 10px;
        }
        &-text {
            font-size: 10px;
            text-transform: capitalize;
            &-one {
                font-size: 11px;
                text-transform: uppercase;
            }
            &-two {
                font-size: 11px;
                font-weight: normal;
            }
        }
    }

    .resources {
        .c3-legend-item {
            display: none;
        }
        .c3-axis-y2,
        .c3-axis-y,
        .c3-axis-x {
            display: none;
        }
    }

    .c3-axis-x .tick {
        text-transform: lowercase;
    }
}
