@use "~styles/constants" as *;

.donut-chart {
    margin: auto;
    font-family: Frutiger;

    .c3-chart-arcs {
        text {
            font-size: 12px;
            font-weight: 500;
            fill: white;
        }
    }
}
