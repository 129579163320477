@use "~styles/constants" as *;

.card-component {
    .button--close {
        content: $svg_fechar;
        position: absolute;
        top: 10px;
        right: 25px;
        @include responsive(s) {
            content: $svg_fechar_mob;
        }
    }

    .MuiCard-root {
        border-radius: 8px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        background: #ffffff;
    }

    .MuiPaper-root {
        background-color: white;
        @include responsive(s) {
            background: $sun_yellow;
        }
    }

    .card__identify--text {
        font-size: 0.813rem;
        padding-right: 22px;
        color: $greyish_brown;
        font-family: 'Frutiger';
        font-weight: 300;
        @include responsive(s) {
            font-size: 0.875rem;
            line-height: 18px;
            padding-right: 0;
        }

        p {
            margin: unset;
            padding-top: 20px;
        }
    }
}
