@use "~styles/constants" as *;

.page {
    padding-top: 80px;
    padding-bottom: 50px;
    @include responsive(s) {
        padding-top: 0px;
        padding-bottom: 0;
    }
}

.container__button {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    @include responsive(s) {
        margin-bottom: 50px;
    }
}

.button--start {
    width: 266px;
    height: 56px;
    background: $organization_primary;
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 0.875rem;
    font-family: 'Frutiger';
    font-weight: 700;
    letter-spacing: 1.5px;
}

.button--start:hover {
    background: $organization_primary_focus;
}
