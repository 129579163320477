@use "~styles/constants" as *;

.account-profile__items {
    font-family: Frutiger;

    .profile-display__items {
        
        .container {
            margin-top: -105px;
            padding-bottom: 120px;        
            & > * {
                margin-top: -90px;
                padding-top: 120px;
            }
        }

        &--title {
            font-size: 1.5rem;
            font-weight: 300;
            color: #3f41d1;
            border-bottom: solid 1px #3f41d1;
            margin-bottom: 7px;
        }
        &--subtitle {     
            font-family: Frutiger;          
            font-size: 0.8rem;          
            font-weight: bold;          
            color:$dark;
        }
        &--text {
            font-family: Catamaran;
            height: 45px;
            border-bottom: solid 1px #e3e3e3;
            display: flex;
            flex-direction: column;
            justify-content: center;
            
            &-first {
                font-size: 12px;
                color: #7b7b7b;
            }
            &-second {
                color: $dark;
                font-size: 1rem;
                margin-top: -5px;
            }
            &-third {                       
                font-size: 1rem;              
                color: #7b7b7b;
            }
        }

        &--text-two {
            font-family: Catamaran;
            height: 45px;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            div {
                flex-direction: column;
            }
            &-first {
                font-size: 12px;
                color: $brown_grey;
            }
            &-second {
                color: $dark;
                font-size: 1rem;
                margin-top: -5px;
            }
        }
        input{
            border: 0;
            background: transparent;
            font-family: Catamaran;
            width: 100%;
        }

        #contact {
            .profile-display__items--text {
                & div:first-child {
                    border: 0;
                    align-items: center;
                    display: flex;
                    .MuiGrid-item:first-child {
                        input {
                            margin-left: 2px;
                            width: fit-content;
                        }
                    }
                    .MuiGrid-item:last-child {
                        display: flex;
                        justify-content: flex-end;
                    }
                }
            }
        }
    }
}