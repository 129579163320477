$svg_ops: url('~images/ops.svg');
$svg_ops-2: url('~images/ops-2.svg');

$svg_fechar: url('~images/fechar.svg');
$svg_fechar_mob: url('~images/fechar-mob.svg');
$svg_fechar-3: url('~images/fechar-3.svg');

$svg_next: url('~images/next.svg');
$svg_next_hover: url('~images/next-hover.svg');

$svg_back: url('~images/back.svg');

$svg_tiny-expand: url('~images/tiny-expand.svg');

$svg_empty: url('~images/empty.svg');

$svg_check: url('~images/check.svg');

$svg_path: url('~images/Path.svg');
$svg_path_2: url('~images/Path2.svg');

$svg_step_check: url('~images/step-check.svg');

$svg_positivo: url('~images/positivo.svg');
$svg_positivo_2: url('~images/positivo-2.svg');

$svg_error: url('~images/error.svg');

$svg_step_active: url('~images/step-active.svg');

$svg_step_complete: url('~images/step-complete.svg');

$svg_step_disabled: url('~images/step-disabled.svg');

$svg_logo_white: url('~images/logo-white.svg');

$svg_giro_tech: url('~images/giro_tech.svg');

$svg_live_indicator: url('~images/live-indicator.svg');

$svg_shape: url('~images/Shape.svg');

$svg_blue_arrow: url('~images/blue-arrow.svg');

$svg_blue_search: url('~images/blue-search.svg');

$svg_pdf_download: url('~images/pdf-download.svg');

$svg_blue_calendar: url('~images/blue-calendar.svg');

$svg_calendar: url('~images/calendar.svg');
$svg_calendar_rest: url('~images/calendar-rest.svg');

$svg_icon_graph: url('~images/icon-graph.svg');

$svg_duvida: url('~images/duvida.svg');

$svg_blue_arrow_cicle: url('~images/blue-arrow-circle.svg');

$svg_green_arrow: url('~images/green-arrow.svg');

$svg_bt_expandir: url('~images/bt-expandir.svg');

$svg_table_arrow: url('~images/table-arrow.svg');

$svg_logo_giro: url('~images/logo-giro.svg');

$svg_contribution_tab: url('~images/contribution-tab.svg');
$svg_rescue_tab: url('~images/rescue-tab.svg');

$svg_gout: url('~images/gout.svg');

$svg_move_arrow: url('~images/move-arrow.svg');

$svg_search: url('~images/search.svg');

$svg_close_hover: url('~images/close-hover.svg');
$svg_close_rest: url('~images/close-rest.svg');

$svg_plus_hover: url('~images/plus-hover.svg');

$svg_download: url('~images/download.svg');
$svg_document_not_found: url('~images/document-not-found.svg');

$svg_arrow: url('~images/arrow.svg');

$svg_favicon: url('https://giro-logos.s3.amazonaws.com/giro/giro_ico_192.png');

$svg_survey: url('~images/survey.svg');
