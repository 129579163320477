@use "~styles/constants" as *;

.income-statement-button-switch {
    display: flex;
    background-color: #f6f6f6;
    border-radius: 20px;

    &--content {
        background-color: #f6f6f6;
        display: flex;
        font-size: 14px;
        font-family: 'Frutiger';
        color: #9199a1;
        width: 80px;
        height: 35px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 20px;

        &.active {
            background-color: white;
            color: #163c6e;
            border: 1px solid #e3e3e3;
            cursor: default;
        }

        &.disabled {
            cursor: default;
        }
    }
}
