@use "~styles/constants" as *;

.secondary-access-header {
    font-family: Frutiger;

    &--container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 5px;

    }

    &--title {
        font-size: 34px;
        line-height: normal;
        font-weight: 500;
        color: #072056;
        font-family: Frutiger-55;
        max-width: 60%;

        span {
            width: 100%;
            display: inline-block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    &--subtitle {
        font-size: 17px;
        font-weight: 500;
        max-width: 300px;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #837e7e;
        margin: 0;
        padding-bottom: 10px;
        font-family: 'Frutiger';
    }
}
