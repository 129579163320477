@use "~styles/constants" as *;

.spline-chart {
    font-family: Frutiger;

    .c3-shape.c3-shape.c3-line {
        fill: transparent;
    }

    .c3-xgrid,
    .c3-ygrid,
    .c3-axis-x .domain {
        stroke: #e1e2e6;
    }
    .c3-xgrid {
        stroke-dasharray: 5;
    }

    .c3-circle {
        fill: currentColor;
    }

    .tick {
        font-family: Frutiger;
        font-size: 11px;
        color: $greyish-brown;
        font-weight: 300;
    }
    .c3-text {
        font-size: 11px;
    }

    .c3-axis-x text {
        font-size: 11px;
        fill: $marine;
        font-weight: normal;
    }
}
