@use "~styles/constants" as *;

.chart-legend {
    font-family: Frutiger;
    display: flex;
    justify-content: center;
    &--items {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;

        &-color {
            width: 10px;
            height: 10px;
            margin-right: 5px;
            display: flex;
            align-items: center;
        }
        &-text {
            font-size: 0.6875rem;
            font-weight: 400;
            line-height: 0.6875rem;
            max-width: 250px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
    .legend {
        display: flex;
        span {
            cursor: pointer;
            font-size: 11px;
            font-weight: 600;
            color: #4a4a4a;
            margin-bottom: 3px;
            display: flex;
            text-transform: uppercase;
        }
    }
}
