@use "~styles/constants" as *;

.button__style--third {
    float: right;

    .MuiButton-contained {
        background-color: $white_two;
        border: solid 1px $very_light_pink_two;
        box-shadow: unset;
    }

    .MuiButton-label {
        justify-content: center;
    }

    .MuiButton-contained:hover {
        background-color: $white_two;
        border: solid 1px $brown_grey;
        box-shadow: unset;

        .button--arrow {
            filter: brightness(0.4);
        }

        span {
            color: $brown_grey;
        }
    }

    .MuiButton-contained:hover,
    .MuiButton-contained {
        color: $brown_grey_three;
        font-weight: 400 !important;
        font-size: 0.875rem;
        font-family: 'Frutiger';
        letter-spacing: 1.5px;
        border-radius: 10px;
        min-height: 46px;
        max-height: 46px;
        width: 100%;
        max-width: 166px;
        min-width: 166px;
        @include responsive(s) {
            max-width: 130px;
            min-width: 130px;
        }
    }

    .button--arrow {
        content: $svg_back;
        color: $brown_grey_three;
        padding-right: 5px;
        @include responsive(s) {
            transform: rotate(90deg);
            padding-right: 0;
            padding-top: 5px;
        }
    }
}
