@use "~styles/constants" as *;

.button__style--fourth {
    .MuiButton-contained {
        background-color: #3f41d1;
        box-shadow: unset;
    }

    .MuiButton-contained:hover {
        background-color: #32348d;
        box-shadow: unset;
    }

    .MuiButton-contained:hover,
    .MuiButton-contained {
        color: #fff;
        font-size: 0.875rem;
        font-family: 'Frutiger';
        font-weight: 500;
        letter-spacing: 1.5px;
        border-radius: 10px;
        min-height: 45px;
        max-height: 45px;
        width: 100%;
        max-width: 170px;
        outline: none;
        @include responsive(s) {
            max-width: 130px;
            min-width: 130px;
        }
    }

    .button--arrow {
        content: $svg_next;
        padding-left: 5px;
        @include responsive(s) {
            transform: rotate(90deg);
            padding-left: 0;
            padding-bottom: 5px;
        }
    }
}
