@use "~styles/constants" as *;

.multi-line-chart {
    .recharts-cartesian-axis-tick-value {
    }

    .recharts-legend-item-text {
        font-size: 0.6875rem;
        font-weight: 400;
        line-height: 0.6875rem;
        max-width: 250px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-transform: uppercase;
        color: #4a4a4a !important;
    }
}
