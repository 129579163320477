@use '~styles/constants' as *;

.date__input {
    min-width: 146px;
    width: 100%;
    &--label {
        display: none;
        font-size: 11px;
        font-weight: 400;
        letter-spacing: 0.54px;
        color: #9199a1;
        position: absolute;
        top: 7px;
        left: 15px;
        font-family: 'Frutiger-light';
    }
    &--calendar-icon {
        content: $svg_calendar_rest;
    }
    &__button {
        padding: 0;
    }
    .react-date-picker {
        background-color: #f6f6f6;
        &--open {
            background-color: $white_two;

            ::placeholder {
                opacity: 0;
            }
            .react-date-picker__wrapper {
                border-color: #aaaaaa;
            }
        }
        &__wrapper {
            background-color: inherit;
            border: solid 1px #e8ebec;
            border-radius: 6px;
            position: relative;
            padding: 13.5px 15px;
            min-width: 146px;
        }
        &__inputGroup {
            padding: 0;
            font-family: 'Frutiger-light';
            font-size: 15px;

            > div {
                color: #9199a1;
                font-size: 14px;
                letter-spacing: 0.541667px;
                font-family: 'Frutiger-light';
            }
        }
        &__calendar {
            z-index: 9999;
            .react-calendar {
                border: solid 1px #d0d0d0;
                border-radius: 6px;
                &__navigation {
                    border-bottom: solid 1px $cool-blue-two;
                    &__arrow {
                        color: $cool-blue-two;
                        font-size: 20px;
                    }
                }
                &__tile {
                    outline: 0;
                    &--now {
                        background: #072056;
                        color: $white_two;
                        &:hover {
                            background-color: #3aadd1b3;
                        }
                    }
                    &--active {
                        background-color: #3aadd1;
                    }
                }
                &__month-view__days__day:hover {
                    background-color: #3aadd180;
                }
            }
        }
        &__button {
            outline: 0;
            padding: 0;
        }
    }
    &--icon {
        content: $svg_calendar_rest;
        height: 18px;
        outline: 0;
        width: 18px;
    }
    &.dirty {
        .date__input--label {
            display: block;
            z-index: 1;
        }
        .react-date-picker__wrapper {
            align-items: center;
            border-color: #aaaaaa;
            background-color: $white_two;
            padding: 21px 15px 6px;
        }
        .react-date-picker__inputGroup {
            padding: 0;
        }
        .react-date-picker__button {
            margin-top: -15px;
            margin-left: 7px;
        }
    }
}

.year__input,
.month__input {
    .MuiButtonBase-root {
        outline: 0;
    }
    .MuiFilledInput-adornedEnd {
        position: relative !important;
    }
    .MuiInputAdornment-positionEnd {
        position: absolute !important;
        top: 0;
        right: 0;
    }
}
