@use "~styles/constants" as *;

.body__contact {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 60px;
    font-family: 'Frutiger';
    @include responsive(s) {
        margin-bottom: 30px;
    }

    &--buttons {
        display: flex;
        margin-bottom: 30px;
        margin: auto;
        @include responsive(ss) {
            flex-wrap: wrap;
        }
        @include responsive(s) {
            margin-top: 20px;
        }
    }

    &--button-previous {
        display: flex;
        justify-content: flex-end;
        @include responsive(ss) {
            justify-content: center;
            padding-bottom: 20px;
        }
    }

    &--button-next {
        display: flex;
        justify-content: flex-start;
        @include responsive(ss) {
            justify-content: center;
        }
    }

    .MuiButtonBase {
        @include responsive(s) {
            width: 100%;
            max-width: 230px;
            margin: auto;
        }
    }

    .MuiCard-root {
        margin-top: 137px;
        @include responsive(s) {
            z-index: 1;
            margin-bottom: 10px;
            margin-top: 0;
        }

        .button--close {
            top: 145px;
            right: 22px;
            position: absolute;
            @include responsive(s) {
                display: none;
            }
        }
    }

    &--button-no {
        text-align: right;
    }

    &--title {
        font-family: 'Frutiger';
        margin-top: 137px;
        display: flex;
        justify-content: center;
        font-size: 1.375rem;
        text-align: center;
        margin-bottom: 45px;
        line-height: 1.2;
        @include responsive(s) {
            margin-top: 10px;
            margin-bottom: 25px;
        }

        br {
            justify-content: center;
        }
    }
}

.field--background {
    background: $white;
    padding-top: 16px;
    padding-bottom: 16px;
    position: relative;
    margin-bottom: 50px;
    padding-left: 16px;
    padding-right: 16px;
    @include responsive(s) {
        margin-bottom: 28px;
    }

    &:before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        background: $white;
        transform: rotate(45deg);
        position: absolute;
        left: 24%;
        top: -6px;
        @include responsive(s) {
            left: 50%;
        }
    }
}

.body__contact--buttons {
    .body__contact--button-previous {
        .button--arrow {
            @include responsive(s) {
                transform: rotate(360deg);
                padding-left: 0;
                padding-bottom: 5px;
            }
        }
    }

    .body__contact--button-next {
        .button--arrow {
            @include responsive(s) {
                transform: rotate(0deg);
                padding-left: 0;
                padding-top: 5px;
            }
        }
    }

    .body__contact--button-finish {
        .button--arrow {
            @include responsive(s) {
                transform: rotate(90deg);
                padding-left: 0;
                padding-top: 5px;
            }
        }
    }
}
