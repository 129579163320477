@use "~styles/constants" as *;

.account-filter-buttons {
    padding: 0 18px;
    margin: 7px 5px;
    height: 43px;
    border-radius: 15px;
    background-color: white;
    color: #072056;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Frutiger';
    font-size: 12px;
    letter-spacing: 1.5px;
    cursor: pointer;
    border: 1px solid #072056;
    min-width: 100px;

    &.ACTIVE {
        background-color: #072056;
        color: #ffffff;
    }
}
