@use "~styles/constants" as *;

.scrollable-lgpd-terms {
    width: 100%;
    position: relative;
    overflow: auto;
    max-height: 330px;
    border: 1px solid #e3e3e3;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding-bottom: 8px;

    &--list-section {
        padding: 40px 15px 0 10px;
    }

    &--list {
        padding: 0;
    }

    &--text {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: Frutiger;
        width: 100%;
        font-size: 0.875rem;
        font-weight: 300;
        padding: 8px 16px;

        &__paragraph {
            margin-top: 0;
            margin-bottom: 16px;
        }

        &__subtitle {
            margin-top: 0;
            margin-bottom: 8px;
        }

        div {
            width: 100%;
        }

        h3 {
            font-size: 1rem;
            margin-bottom: 25px;
        }

        h4 {
            font-size: 14px;
        }

        ul {
            padding-left: 20px;
        }

        li {
            list-style-type: '-';
            padding: 0 3px;
        }
    }
}
