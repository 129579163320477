@use "~styles/constants" as *;

.button__style--action {
    .MuiButton-contained {
        background-color: $organization_primary;
        box-shadow: unset;
    }

    .MuiButton-contained:hover {
        background-color: $organization_primary_focus;
        box-shadow: unset;
    }

    .MuiButton-label {
        justify-content: center;
    }

    .MuiButton-contained:hover,
    .MuiButton-contained {
        color: $white_two;
        font-size: 0.875rem;
        font-family: Frutiger;
        font-weight: 500;
        letter-spacing: 1.5px;
        border-radius: 10px;
        width: 100%;
        max-width: 172px;
        min-width: 172px;
        min-height: 46px;
        max-height: 46px;
        @include responsive(s) {
            max-width: 130px;
            min-width: 130px;
        }
    }

    .MuiButton-contained.Mui-disabled {
        background: $organization_primary_disabled;
        cursor: not-allowed;

        span {
            color: white;
        }
    }

    .sc-bwzfXH.sc-EHOje.hkQVFp,
    .sc-bwzfXH.sc-htpNat.flDhnP,
    .sc-bwzfXH.sc-bxivhb.kINVDU,
    .sc-bwzfXH.sc-ifAKCX.gJwEBr {
        .sc-bZQynM.iepmoa {
            background: white;
            width: 5px;
            height: 5px;
        }
    }

    .gfLNMF {
        width: 10px;
        height: 10px;
    }
}
