@use "~styles/constants" as *;

.error-modal__itens {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--img {
        content: url(../../images/ops-2.svg);
        width: 120px;
        margin-bottom: 20px;
        @include responsive(s) {
            width: 150px;
        }
    }

    &--text {
        font-family: 'Catamaran', sans-serif;
        font-size: 1.3rem;
        color: #5e5e5e;
        text-align: center;
        line-height: 20px;
        white-space: pre-wrap;
        margin-bottom: 15px;
        margin-bottom: 25px;
        @include responsive(s) {
            font-size: 1rem;
        }
    }

    &--button {
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid 1px #c6c6c6;
        border-radius: 10px;
        width: 150px;
        height: 40px;
        background: white;
        color: $brown_grey_three;
        font-family: frutiger;
        font-size: 0.875rem;
        padding: 12px;
        cursor: pointer;
    }

    &--button:hover {
        border: solid 1px $brown_grey;
        color: $brown_grey;
    }
}
