@use '~styles/constants' as *;

.select-graphs {
    &--menu-list {
        .MuiListItem-root {
            font-size: 0.75rem;
            color: #4a4a4a;
        }
    }

    .Mui-focused {
        fieldset {
            border: none;
        }
    }

    .MuiSelect-root {
        height: 25px;
        font-size: 0.75rem;
        color: #4a4a4a;
        border: 1px solid #b9b9b9;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin: 0;
        padding: 2px 13px;
        border-radius: 4px;
        outline: none;
    }

    .Mui-disabled {
        opacity: 0.5;
    }
}
