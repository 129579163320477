@use "~styles/constants" as *;


.container__header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    background: #ffffff;
    z-index: 10;
    @include responsive (s) {
        height: 60px;
        position: relative;
    }
} 

.header {
    display: flex;
    justify-content: flex-start;
    height: 80px;
    @include responsive (s) {
        height: 60px;
    }

}

.header--logo {
    margin: 8px;
    content: $svg_giro_tech;
    display: flex;
    align-items: center;
    @include responsive (s) {
        width: 80px;
    }
}