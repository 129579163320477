@use '~styles/constants' as *;

.button__style--standard-outlined {
    .MuiButton-label {
        justify-content: center;
        margin-top: 3px;
        white-space: nowrap;
    }

    &.small {
        .MuiButton-contained:hover,
        .MuiButton-contained {
            font-size: 0.75rem;
            letter-spacing: 1.29px;
            max-height: 36px;
            max-width: 110px;
        }
    }

    &.medium {
        .MuiButton-contained:hover,
        .MuiButton-contained {
            font-size: 0.8125rem;
            letter-spacing: 1.29px;
            max-height: 40px;
            padding: 5px 15px;
        }
        .MuiButton-label {
            margin-top: 3px;
        }
    }

    .MuiButton-contained {
        border: solid 1px $blurple;
        color: $blurple;

        &:hover {
            border-color: $blurple_focus;
            color: $blurple_focus;
        }
    }

    .MuiButton-contained:hover,
    .MuiButton-contained {
        background-color: $white_two;
        box-shadow: unset;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 1.5px;
        border-radius: 8px;
        height: 100%;
        max-height: 46px;
        padding: 10px 25px;
        outline: none;
        font-family: 'Frutiger';

        &.Mui-disabled {
            background-color: $white_two;
            border-color: #ececec;
            color: #c6c6c6;
        }
    }

    .button--arrow {
        color: $brown_grey_three;
    }
}
