@use 'node_modules/bootstrap/scss/bootstrap';
@use '~styles/constants' as *;

body {
    background: white;
    margin: 0;
    font-family: Frutiger, Raleway;
    color: #4a4a4a;
}

b {
    font-weight: 550;
}

.page-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: auto;
}
