.modal {
    height: 100vh;
    width: 100vw;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
}

.modal-dialog {
    width: 430px !important;
}

.select-option-modal-card-style {
    border-radius: 5px;
    background-color: #ffffff;
}

.select-option-modal-content-container {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
}

.select-option-modal-title-style {
    font-size: 20px;
    font-weight: bold;
    color: #292a2a;
    letter-spacing: 0.3px;
    line-height: 1.2;
    margin: 0;
}

.select-option-modal-div-style {
    margin-top: 20px;
    overflow: auto;
    max-height: 160px;
    height: 160px;
    scrollbar-color: #a4a4a4 #e1e2e6;
    scrollbar-width: thin;
}

.select-option-modal-div-style::-webkit-scrollbar {
    width: 4px;
    background-color: #e1e2e6;
}

.select-option-modal-div-style::-webkit-scrollbar-thumb {
    width: 3px;
    background: #a4a4a4;
}

.select-option-modal-content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;
}

.select-option-modal-item-container {
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.56px;
    padding: 12px 20px;
    display: flex;
    color: #111518;
    margin-right: 5px;
}

.select-option-modal-item-container:hover {
    border-radius: 4px;
    background-color: #3f41d1;
    cursor: pointer;
    color: #ffffff;
}

.select-option-modal-search-input-container {
    margin: 0;
}

.select-option-modal-search-input {
    width: 300px;
    height: 45px;
    border-radius: 22.5px;
    border: solid 1px #d6d9db;
    outline: 0px;
}
.select-option-modal-label {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    margin: 0;
    letter-spacing: 0.54;
}

.select-option-modal-buttons-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    > :nth-child(2) {
        margin-left: 25px;
    }
}
