@use '~styles/constants' as *;
.select-input {
    width: 100%;
    
    .MuiInputBase,
    .MuiFormControl,
    .MuiSelect {
        &-root {

            border-radius: 6px;
            background: none;
            max-height: 50px;
            padding: 0;

            &:hover {
                background: none;
            }
        }
    }

    .MuiSelect-filled.MuiSelect-filled {
        padding: 15px;

        & :focus {
            padding: 20px 15px 9px;
        }
    }

    .MuiFilledInput-root.Mui-focused,
    .MuiSelect-select:focus {
        background: none;
        max-height: 100%;
    }

    [value=''] {
        color: #9199a1;

        &:focus::placeholder {
            opacity: 1 !important;
            z-index: 2;
            position: relative;
        }
    }

    .MuiSelect-nativeInput {
        opacity: 1;
        border-radius: 6px;
        font-size: 15px;

        &:focus::placeholder {
            opacity: 0;
        }

        &:not(:focus),
        &[value=''] {
            padding: 15px;
            border: solid 1px #aaaaaa;
            border-radius: 6px;
            background-color: #ffffff;

            &::placeholder {
                opacity: 1 !important;
            }
        }
    }
    label {
        display: none;
    }

    label[data-shrink='false'] + .MuiInputBase-formControl .MuiInputBase-input::placeholder {
        color: #9199a1;
        font-size: 15px;
        opacity: 1 !important;
    }

    &.dirty {
        label {
            display: block !important;
            transform: translate(15px, 8px) scale(0.75) !important;
            font-size: 13px;
            z-index: 2;
            color: $bluey_grey;
        }

        .MuiSelect-root {
            padding: 22px 15px 5px;
        }

        .MuiSelect-select {
            border-radius: 6px;
            font-size: 16px;
            background-color: #ffffff;
            border: solid 1px #aaaaaa;
        }

        .MuiSelect-nativeInput {
            opacity: 0;
            border-radius: 6px;
            font-size: 16px;

            &:focus::placeholder {
                opacity: 0;
            }

            &:not(:focus),
            &:not([value='']) {
                padding: 22px 15px 5px;
                background-color: #ffffff;
                border: solid 1px #aaaaaa;

                &::placeholder {
                    opacity: 0 !important;
                }
            }
        }
    }

    .MuiFilledInput-underline::before,
    .MuiFilledInput-underline::after {
        display: none;

        &.Mui-focused::after {
            display: block;
            border-bottom: 1px solid #dddddd;
        }
    }
    .MuiFormHelperText-root.Mui-error {
        font-size: 0.813rem;
        font-family: 'Frutiger';
        margin-left: 14px;
    }
}

.MuiPaper-elevation8 {
    box-shadow: none !important;
    border: solid 1px #aaaaaa;
}
.MuiMenuItem-root {
    width: 100% !important;
}
