@use '~styles/constants' as *;

.embeded-form {
    width: 65px;
    height: 65px;
    border: none;
    border-radius: 50%;
    background-color: transparent;
    background-image: $svg_survey;
    background-size: auto;
    background-position: center;
    background-repeat: no-repeat;
    position: fixed;
    right: 26px;
    bottom: 20px;
    cursor: pointer;
    animation: bounce 4s ease 0s infinite normal forwards;

    &:hover {
        width: 67px;
        height: 67px;
        transition: all 0.3s;
    }
}

.tooltip-wrapper {
    width: 65px;
    height: 65px;
    position: fixed;
    right: 26px;
    bottom: 26px;
    z-index: 100;
}

@keyframes bounce {
    0%,
    25%,
    50% {
        transform: translateY(0);
    }

    5%,
    15%,
    25% {
        transform: translateY(-8px);
    }

    10%,
    20% {
        transform: translateY(8px);
    }

    30% {
        transform: translateY(6.4px);
    }

    35% {
        transform: translateY(-6.4px);
    }
}

.MuiTooltip-tooltip {
    background-color: #fff !important;
    color: rgba(97, 97, 97, 0.9) !important;
    border: 1px solid rgba(97, 97, 97, 0.9);
    font-size: 15px !important;
    max-width: 350px !important;
    font-family: "Frutiger"!important;
    padding: 10px 15px!important;
}
