@use "~styles/constants" as *;

.MuiStepper-root {
    padding: 0px;
}

.step--active {
    z-index: 20;
    width: 23px;
    height: 23px;
    display: inline-block;
    mask: $svg_step_active;
    mask-size: cover;
    background-color: $organization_secondary;
}

.step--complete {
    z-index: 20;
    width: 23px;
    height: 23px;
    display: inline-block;
    mask: $svg_step_complete;
    mask-size: cover;
    background-color: $organization_secondary;
}

.step--disabled {
    margin-top: 6px;
    z-index: 20;
    width: 9px;
    height: 9px;
    display: inline-block;
    mask: $svg_step_disabled;
    mask-size: cover;
    background-color: $organization_primary_disabled;
}

.MuiStepConnector-line.MuiStepConnector-lineHorizontal {
    border-top: solid 1px $very_light_pink_three;
    width: 78px;
    margin-left: -5px;
}

.MuiStepConnector-completed,
.MuiStepConnector-active {
    .MuiStepConnector-line.MuiStepConnector-lineHorizontal {
        border-color: $organization_secondary_focus;
    }
}

.Mui-disabled {
    .MuiStepConnector-line.MuiStepConnector-lineHorizontal {
        width: 85px;
    }
}

.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
    background-color: $white_two;
}

.MuiStepper-alternativeLabel {
    width: 450px;
    margin: auto;
    margin-top: -35px;
    position: fixed;
    z-index: 100;
    left: 0;
    right: 0;
    top: 80px;
}

.MuiStepLabel-labelContainer {
    margin-top: -55px;
    text-transform: uppercase;
    z-index: 20;

    span {
        font-size: 0.688rem;
        font-family: 'Frutiger';
        font-weight: 400;
        color: $very_light_pink_three;
    }

    .MuiStepLabel-label.MuiStepLabel-active,
    .MuiStepLabel-label.MuiStepLabel-completed {
        color: $organization_primary;
        margin-top: 8px;
        font-weight: 400;
    }
}

.page {
    .MuiStepper-alternativeLabel {
        background: transparent;
        @include responsive(s) {
            display: none;
        }
    }
}
