$black: #000000;
$white_two: #ffffff;
$white_three: #f6f6f6;
$white: #f5f5f5;
$alabaster: #fafafa;
$light-periwinkle: #e1e2e6;
$cloudy_blue: #c3c7cb;
$light_periwinkle: #e1e2e6;
$ice_blue: #e8ebec;
$bluey_grey: #9199a1;
$greyish_brown: #4a4a4a;
$very_light_pink: #f2f2f2;
$very_light_pink_two: #e3e3e3;
$brown_grey: #7b7b7b;
$brown_grey_two: #aaaaaa;
$very_light_pink_three: #b9b9b9;
$brown_grey_three: #a4a4a4;
$scorpion: #5e5e5e;
$mercury: #e3e3e3;
$black_squeeze: #f4f8fb;
$link_water: #d4e5f0;
$cool_blue: #3aadd1;
$cool_blue_two: #32aacf;
$denim_blue: #39549e;
$dusky_blue: #486094;
$picton_blue: #51cdf3;
$madison: #072056;
$gross_green: #9ac91c;
$greenish_teal: #34c7a9;
$turquoise: #00bfa5;
$sick_green: #9bc731;
$sickly_green: #86b31a;
$soft_green: #6dbc70;
$piss_yellow: #d9cc18;
$yellowish: #f6ed71;
$sun_yellow: #e6da3c;
$pumpkin: #ef7d00;
$punch: #e04444;

// organization
$organization_primary: #3f41d1;
$organization_primary_focus: #32348d;
$organization_primary_disabled: #3f41d14d;

$organization_program_default: #406afa;

$organization_secondary: #072056;
$organization_secondary_focus: #784af4;

$organization_secondary_transparent: #030b1db3;

$organization_secundary_border: #486094;

$organization_secundary_link: #32aacf;
$organization_secundary_link_hover: #51cdf3;

$organization_secundary_button: #3f41d1;
$organization_secundary_button_focus: #32348d;

// ======= DEBENTURISTAS ======
$marine: #072056;
$greyish-brown: #4a4a4a;
$cool-blue: #32aacf;
$light-navy-blue: #2b4b77;
$ice-blue: #e4f2f8;
$pale-grey: #f4fcff;
$dull-blue: #4d6f9e;
$faded-blue: #6188bc;
$gross-green: #9ac91c;
$dark: #2d3e46;
$white: #fafafa;
$faded-blue-two: #5790be;
$sky-blue: #64b5f7;
$dark-sky-blue: #319ff8;
$coral: #fa5454;
$powder-blue: #bae1ed;
$light-peach: #facdcd;
$brown-grey: #a4a4a4;
$light-periwinkle: #e1e2e6;
$very-light-pink: #b9b9b9;
$cool-blue-two: #3aadd1;
$dark-blue-grey: #183541;
$blurple: #3f41d1;
$blurple_focus: #282a86;

