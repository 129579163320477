@use "~styles/constants" as *;

.number__field {

    .MuiFormControl-root {
        border: solid 1px $brown_grey_two;
        border-radius: 6px;
        background-color: #fff;
        font-family: 'Frutiger';
        font-size: 0.8125rem;
        width: 100%;
        overflow: hidden;
    }

    input#formatted-text-mask-input.MuiInputBase-input.MuiInput-input { 
        box-shadow: white 500px 500px inset;
    }

    .MuiInputAdornment-positionEnd {
        position: absolute;
        top: 27px;
        right: 10px;
        color: $gross_green;
    }

    .MuiFormLabel-root.Mui-focused {
        color: $brown_grey;
        z-index: 1;
    }

    // .MuiSvgIcon-root {
    //     z-index: 1;
    // }

    .MuiInput-underline:after {
        border-bottom: transparent;
        top: -16px;
        bottom: unset;
        margin: auto;
        width: 99%;
    }

    .MuiInput-underline:before {
        border: none;
    }

    .MuiInput-underline:hover:not(.Mui-disabled):before {
        border: none;
    }

    .MuiInputBase-input {
        font-family: 'Frutiger';
        font-size: 1rem;
        color: #000000;
        padding: 10px 0px 9px 14px;
    }

    .MuiFormLabel-root {
        padding: 10px 0px 10px 20px;
    }

    .phone--error {
        font-family: 'Frutiger';
        font-size: 0.813rem;
        color: red;
        padding-left: 16px;
        padding-top: 5px;
    }
}
