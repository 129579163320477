@use "~styles/constants" as *;

.card-second-table--title-img {
    content: $svg_shape;
}

.ReactVirtualized__Table__row {
    border-bottom: solid 1px #d0d0d0;
}

.ReactVirtualized__Table__row:hover {
    background: #f4fcff;
}

.ReactVirtualized__Table__headerRow {
    font-weight: 500 !important;
    font-size: 12px;
    font-family: 'Frutiger';
    color: #3aadd1;
    border-bottom: solid 2px #3aadd1;
}

.ReactVirtualized__Table__sortableHeaderColumn {
    display: flex;
    align-items: center;
}

.ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
}

.detail__style {
    cursor: pointer;
    font-family: Frutiger;
    font-size: 0.6875rem;
    line-height: 3.09;
    font-weight: 400;
    color: #32aacf;
}

.empty-account-list {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 340px;
    width: 100%;
    flex-direction: column;

    &--icon {
        content: $svg_move_arrow;
        height: 70px;
    }

    &--title {
        font-weight: 500;
        font-size: 24px;
        color: #072056;
        padding-top: 15px;
        font-family: 'Frutiger';
    }

    &--text {
        font-weight: 400;
        font-size: 16px;
        color: #072056;
        padding-top: 5px;
        font-family: 'Frutiger';
    }
}

.dinamic-height {
    height: 300px;
    border: 1px solid #e3e3e3;

    @media screen and (min-height: 900px) {
        height: 350px;
    }
}
