@use "~styles/constants" as *;

.anchor-header {
    height: 60px;
    background: $marine;
    overflow: hidden;

    &__container {
        display: flex;
        justify-content: flex-start;
        height: 60px;
        font-family: 'Frutiger';

        &--logo {
            margin: 8px;
            content: $svg_logo_white;
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        &--text-style {
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: space-around;
        }

        &--text {
            font-size: 1.125rem;
            font-family: 'Frutiger';
            font-weight: 300;
            line-height: 1.33;
            color: #fafafa;

            span {
                font-weight: 400;
            }
        }

        &--profile {
            height: 100%;
            font-family: 'Frutiger';
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-size: 0.875rem;
            line-height: 1.71;
            color: #fafafa;

            span {
                color: #32aacf;
                margin-left: 5px;
            }

            span:hover {
                color: #51cdf3;
                transition: 0.2s;
                cursor: pointer;
            }
        }

        &--loading {
            .gfLNMF {
                height: 10px;
                width: 10px;
                .iepmoa {
                    height: 5px;
                    width: 5px;
                    background-color: $white_two;
                }
            }
        }
    }

    &__awareness {
        &--loading {
            .gfLNMF {
                height: 30px;
                width: 30px;
                .iepmoa {
                    height: 9px;
                    width: 9px;
                    background-color: $organization_primary;
                }
            }
        }

        &--error {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 100vh;
            flex-direction: column;

            &-message {
                font-family: 'Catamaran', sans-serif;
                font-size: 1.25rem;
                color: $scorpion;
                text-align: center;
                line-height: 20px;
                margin-bottom: 30px;
            }

            .button__style--back .MuiButton-contained:hover,
            .button__style--back .MuiButton-contained {
                max-width: 220px;
            }
        }
    }

    &__tab-title {
        font-family: Frutiger;

        &--flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px 0 0px 0;

            div {
                &:last-child {
                    width: 200px;
                }
            }
        }

        &--container {
            padding-top: 98px;
            background-color: #fff;
        }

        &--items {
            width: 100%;

            span {
                font-family: 'Frutiger-55';
                color: #072056;
                font-size: 34px;
                font-weight: 500;
                display: inline-block;
                width: 80%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }

    &__tab-subtitle {
        font-size: 17px;
        font-weight: 500;
        max-width: 300px;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #837e7e;
        margin: 0;
        padding-bottom: 10px;
        font-family: 'Frutiger';
    }

    &__tab-menu {
        font-family: Frutiger;
        &--items {
            font-size: 1rem;
            list-style-type: none;
            padding: 0 10px 0 0;
            display: flex;
            justify-content: flex-start;
            cursor: pointer;
            border-bottom: solid 1px $light-periwinkle;
            margin: 0 10px 0 0;
            align-items: flex-start;

            li {
                padding: 0px 20px;
            }

            li a {
                color: $cool-blue;
                position: relative;
                line-height: 40px;
                margin-right: 10px;
                font-size: 1.125rem;
                border-bottom: solid 2px transparent;
                text-decoration: none;
            }
            li a:hover {
                color: $marine;
            }
            li a.link-is-active {
                color: $marine;
                border-bottom: solid 2px $marine;
                font-size: 1.125rem;
                padding-bottom: 9px;
            }
            &-img {
                content: $svg_live_indicator;
                position: absolute;
                bottom: 27px;
                left: 20px;
            }
        }
    }

    &--button {
        font-size: 12px;
        cursor: pointer;
        font-weight: 500;
        color: #3f41d1;
        width: 140px !important;
        height: 40px;
        border-radius: 5px;
        background-color: white;
        border: 1px solid #3f41d1;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }
        &:active {
            color: #2e3293;
            border: 1px solid #2e3293;
        }
    }
}
.anchor-header__fixed-header--container .anchor-header__fixed-header {
    background-color: transparent;
    transition: background 250ms ease, box-shadow 250ms ease;
}

.anchor-header__fixed-header--container.active .anchor-header__fixed-header {
    box-shadow: 0 1px 2px 0 $light-periwinkle;
    background-color: white;
    .anchor-fixed-header__items {
        &--menu {
            border-bottom: none;
        }
    }
}

.anchor-header__fixed-header {
    z-index: 1;
}
