@use "~styles/constants" as *;

.button__style {
    .MuiButton-contained {
        background-color: #3f41d1;
    }
    
    .MuiButton-contained:hover {
        background-color: #32348d;
    }

    .MuiButton-contained:hover,
    .MuiButton-contained {
        color: #fff;
        font-size: 0.875rem;
        font-family: 'Frutiger';
        font-weight: 500;
        letter-spacing: 1.5px;
        border-radius: 10px;
        padding: 15px 30px 15px 30px; 
    }

    .sc-bwzfXH.sc-EHOje.hkQVFp,
    .sc-bwzfXH.sc-htpNat.flDhnP,
    .sc-bwzfXH.sc-bxivhb.kINVDU,
    .sc-bwzfXH.sc-ifAKCX.gJwEBr {
        .sc-bZQynM.iepmoa {
            background: white;
            width: 5px;
            height: 5px;
        }
    }

    .gfLNMF {
        width: 10px;
        height: 10px;
     }
}
