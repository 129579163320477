@use "~styles/constants" as *;

.y-line-chart {
    font-family: Frutiger;

    .c3-shape.c3-shape.c3-line {
        fill: transparent;
    }

    .c3-ygrid,
    .c3-axis-x .domain {
        stroke: #e1e2e6;
    }

    .c3-circle {
        fill: currentColor;
    }

    .tick {
        font-family: Frutiger;
        font-size: 11px;
        color: #072056;
    }
}
