@use "~styles/constants" as *;

.account-profile-editing__items {
    .profile-editing__items {
        background: $white_two;
        font-family: Frutiger;
        width: 100%;
        .container {
            margin-top: -105px;
            padding-bottom: 120px;
            & > * {
                margin-top: -90px;
                padding-top: 120px;
            }
        }
        &--fields {
            margin-top: 30px;
            & > div {
                margin-bottom: 15px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                & > .number__field,
                & > .password__style--field {
                    width: 100%;
                }
            }
        }

        &--address {
            // display: flex;
            // grid-gap: 20px;
            margin-top: 30px;

            & > div {
                margin-bottom: 15px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                & > .number__field,
                & > .password__style--field {
                    width: 100%;
                }
            }
        }

        &--data-bank {
            // display: flex;
            // grid-gap: 20px;
            margin-top: 30px;

            & > div {
                margin-bottom: 15px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                & > .number__field,
                & > .password__style--field {
                    width: 100%;
                }
            }
        }
        .data-bank-container {
            margin-top: 16px;
            padding: 5px 20px;
            border-radius: 8px;
            background-color: #f5f5f5;
        }
        .profile-editing__items {
            .container {
                margin-top: -105px;
                padding-bottom: 120px;
                & > * {
                    margin-top: -90px;
                    padding-top: 120px;
                }
            }

            &--title {
                font-size: 1.5rem;
                font-weight: 300;
                color: #3f41d1;
                border-bottom: solid 1px #3f41d1;
                margin-bottom: 7px;
            }
            &--subtitle {
                font-family: 'Frutiger-55';
                font-size: 0.8rem;
                color: $dark;
            }
            &--text {
                font-family: Catamaran;
                height: 45px;
                border-bottom: solid 1px #e3e3e3;
                display: flex;
                flex-direction: column;
                justify-content: center;

                &-first {
                    font-size: 12px;
                    color: #7b7b7b;
                }
                &-second {
                    color: $dark;
                    font-size: 1rem;
                    margin-top: -5px;
                }
                &-third {
                    font-size: 1rem;
                    color: #7b7b7b;
                }
            }

            &--text-two {
                font-family: Catamaran;
                height: 45px;
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                div {
                    flex-direction: column;
                }
                &-first {
                    font-size: 12px;
                    color: $brown_grey;
                }
                &-second {
                    color: $dark;
                    font-size: 1rem;
                    margin-top: -5px;
                }
            }
            input {
                border: 0;
                background: transparent;
                font-family: Catamaran;
                width: 100%;
            }
        }
        &--new-account {
            border: none;
            vertical-align: middle;
            cursor: pointer;
            margin-top: 16px;
            font-family: 'Frutiger-55';
            display: inline-block;
            background-color: #3f41d1;
            border-radius: 5px;

            & > span {
                color: white;
                font-size: 14px;
                padding: 8px 14px;
            }
        }

        &--new-account:hover {
            background-color: #595bd1;
        }

        &--new-account:disabled {
            display: none;
        }

        &--save {
            border: none;
            vertical-align: middle;
            cursor: pointer;
            margin-top: 16px;
            font-family: 'Frutiger-55';
            display: inline-block;
            background-color: #3f41d1;
            border-radius: 5px;

            & > span {
                color: white;
                font-size: 14px;
                padding: 8px 14px;
            }
        }

        &--save:hover {
            background-color: #595bd1;
        }

        &--save:disabled {
            background-color: #7b7b7b;
        }

        &--cancel {
            border: none;
            vertical-align: middle;
            cursor: pointer;
            margin-top: 16px;
            font-family: 'Frutiger-55';
            display: inline-block;
            background-color: #cebaba;
            border-radius: 5px;

            & > span {
                color: black;
                font-size: 14px;
                padding: 8px 14px;
            }
        }

        &--cancel:hover {
            background-color: #c0b6b6;
        }

        &--block {
            border-bottom: solid 1px $very_light_pink_two;
            align-items: center;
            display: grid;
            grid-template-columns: 2fr 1fr;
            grid-template-rows: 2fr auto;
            grid-template-areas:
                'a a b'
                'c c .';
            padding: 0 4px 18px 10px;
            &-first {
                grid-area: a;
                padding: 5px 0;
                font-family: Catamaran;
                font-size: 1rem;
                color: #7b7b7b;
            }
            &-second {
                grid-area: b;
            }
            &-third {
                grid-area: c;
                height: auto;
            }
        }
        // Inputs

        .MuiFilledInput-root {
            border-radius: 6px;
        }
        .MuiFilledInput-input {
            padding-left: 16px;
        }
        .MuiFormControl-marginNormal {
            margin-top: 0;
        }
        .MuiInputAdornment-positionEnd {
            display: none;
            padding-right: 6px;
        }
        .Mui-focused {
            .MuiInputAdornment-positionEnd {
                display: flex;
            }
        }

        #data {
            .text__field .MuiFormLabel-root,
            .text__field .MuiFormLabel-root.Mui-focused {
                padding-left: 8px !important;
            }
        }
        #address {
            .text__field .MuiFormLabel-root,
            .text__field .MuiFormLabel-root.Mui-focused {
                padding-left: 8px !important;
            }
        }
        #data-bank {
            .text__field .MuiFormLabel-root,
            .text__field .MuiFormLabel-root.Mui-focused {
                padding-left: 8px !important;
            }
        }
        #password {
            .MuiInputBase-root {
                padding: 0;
            }
            .MuiFormControl-root {
                .MuiInputBase-root {
                    & input[value='']:not(:focus) {
                        background-color: $white_three;
                        border-radius: 6px;
                        .MuiFilledInput-root {
                            border: 0;
                        }
                    }
                }
            }
            .MuiFilledInput-underline {
                &.Mui-error {
                    & input[value='']:not(:focus) {
                        background-color: $white_two;
                    }
                }
            }
            input:not([value='']) {
                & ~ .MuiInputAdornment-positionEnd {
                    display: flex;
                }
            }
            .password--error {
                border: 0;
            }
        }
        #contact {
            .Mui-disabled::before {
                border-bottom: 0;
                background-color: #f0f0f0;
                width: 100%;
                height: 54px;
                opacity: 0.4;
            }
        }
    }
}
