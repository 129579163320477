@use "~styles/constants" as *;

.body__contact--button-yes {
    border: solid 1px $organization_primary;
    border-radius: 10px;
    display: flex;
    padding: 10px 0px 10px 10px;
    max-width: 230px;
    max-height: 43px;
    cursor: pointer;
    margin-bottom: 25px;
    @include responsive(s) {
        margin: auto;
        margin-bottom: 20px;
    }

    span {
        margin-left: 10px;
        font-size: 0.8125rem;
        color: $organization_primary;
        letter-spacing: 1.5px;
    }

    &.active {
        background-color: $ice_blue;
    }
}

.body__contact--button-no {
    border: solid 1px $organization_primary;
    border-radius: 10px;
    display: flex;
    padding: 10px 0px 10px 10px;
    max-width: 230px;
    max-height: 43px;
    float: right;
    width: 100%;
    cursor: pointer;
    @include responsive(s) {
        float: none;
        margin: auto;
        margin-bottom: 20px;
    }

    span {
        margin-left: 10px;
        font-size: 0.8125rem;
        color: $organization_primary;
        letter-spacing: 1.5px;
    }

    &.active {
        background-color: $ice_blue;
    }
}

.body__contact--button-no:hover,
.body__contact--button-yes:hover {
    opacity: 0.7;
}

.button__contact--empty {
    width: 21px;
    height: 21px;
    display: inline-block;
    mask: $svg_empty;
    mask-size: cover;
    background-color: $organization_primary;
}

.button__contact--full {
    width: 21px;
    height: 21px;
    display: inline-block;
    mask: $svg_check;
    mask-size: cover;
    background-color: $organization_primary;
}
