@use '~styles/constants' as *;

.search-input {
    width: 100%;
    font-family: 'Frutiger';

    .MuiFormControl,
    .MuiInputBase {
        &-root {
        border: solid 1px #aaaaaa;
        border-radius: 6px;
        background-color: #ffffff;
        color: #000000;
        }
    }

    .MuiFormLabel-root {
        color: #9199a1;
        opacity: 0;
        z-index: 2;
        font-size: 16px;
        font-family: 'Frutiger';
        transform: translate(15px, 9px) scale(0.75) !important;

        &.Mui-focused {
            color: $brown_grey;
            opacity: 1;
        }
    }

    .MuiButtonBase-root {
        outline: 0;
    }

    .form-control,
    .form-control:focus {
        box-shadow: none;
        border: none;
        border-radius: 6px;
    }

    .MuiInputBase {
        &-root,
        .form-control {
            padding: 0;
        }
        &-input,
        .form-control {
            font-size: 1rem;
            font-family: 'Frutiger';
            padding: 19px 27px 15px 21px;
            font-weight: 400;
            line-height: 1;
            letter-spacing: 0.00938em;
        }
    }

    label[data-shrink='false'] + .MuiInputBase-formControl .MuiInputBase-input::placeholder {
        opacity: 1 !important;
        padding-left: 8px !important;
        color: #9199a1;
    }

    .Mui-focused {
        ::placeholder {
            opacity: 0;
        }
    }

    .MuiButtonBase-root {
        padding: 12px;
        &:hover {
            background: none;
        }
    }

    .MuiFilledInput-underline::before,
    .MuiFilledInput-underline::after {
        display: none;
        &.Mui-focused::after {
            display: block;
            border-bottom: 1px solid #dddddd;
        }
    }

    .MuiInputAdornment-root {
        background-color: inherit !important;
        margin: 0;
        .MuiIcon-root {
            display: flex;
            align-items: center;
            justify-content: center;
            .search--icon {
                content: $svg_search;
                display: flex;
                width: 60%;
            }
        }
    }
    .MuiFormLabel.Mui-focused {
        color: #9199a1;
        font-size: 15px;
        opacity: 1 !important;
    }

    input:not([readonly]):focus {
        padding: 24px 0px 7px 15px;
    }

    .Mui-focused {
        &label {
            opacity: 1 !important;
        }
        &.MuiFormControl,
        &.MuiInputBase {
            &-root {
                border-radius: 6px;
                background-color: #ffffff !important;
                border: solid 1px #aaaaaa;
            }
        }
    }

    &.readonly {
        .MuiFormControl,
        .MuiInputBase {
            &-root {
                border: solid 1px #aaaaaa;
                border-radius: 6px;
                background-color: #ffffff;
                color: #000000;
            }
        }
        .MuiFormLabel-root {
            color: #9199a1;
            opacity: 0;
            z-index: 2;
            font-size: 13px;
            transform: translate(15px, 9px) scale(0.75) !important;
        }
        .MuiInputBase-input::placeholder {
            opacity: 1 !important;
            color: #9199a1;
        }
    }

    &.dirty {
        label {
            opacity: 1 !important;
            padding-left: 5px;
        }
        .form-control,
        .MuiInputBase-input {
            padding: 24px 0px 7px 15px;
        }
        .MuiFormControl,
        .MuiInputBase {
            &-root {
                border-radius: 6px;
                background-color: #ffffff !important;
                border: solid 1px #aaaaaa;
            }
        }
    }

    &.searchActive {
        .MuiFormControl,
        .MuiInputBase {
            &-root {
                border-radius: 6px;
                background-color: #ffffff !important;
                border: solid 1px #aaaaaa;
            }
        }
    }
    .MuiFormHelperText-root.Mui-error {
        font-size: 0.813rem;
        font-family: 'Frutiger';
        margin-left: 14px;
    }
}
