@use "~styles/constants" as *;

.account__items {
    font-family: Frutiger;
    display: flex;
    place-content: center center;
    margin: 0 auto;
    padding: 30px 0 70px 0;
    width: 100%;

    @media screen and (min-height: 900px) {
        padding: 30px 0 50px 0;
    }

    &--timeline {
        background: #072056;
        height: 200px;
        position: relative;
        width: 100%;
        border-radius: 4px;

        &__no-results {
            text-align: center;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            height: 100%;

            &-logo-giro {
                content: $svg_logo_giro;
                height: 253px;
                position: absolute;
                top: 0px;
                left: 0px;
            }

            &-text {
                color: #ffffff;
                font-weight: bold;
                font-size: 24px;
                z-index: 5;
            }

            &-button {
                color: #32aacf;
                padding: 11px 26px;
                border: 1px solid #32aacf;
                border-radius: 10px;
                margin-top: 20px;
                cursor: pointer;
                font-family: 'Frutiger-55';
                letter-spacing: 1.5px;
                font-weight: 500;
                font-size: 14px;

                &:hover {
                    background-color: #ffff;
                    color: #072056;
                    border: 1px solid #ffff;
                }
            }
        }

        &__content {
            display: flex;
            overflow: auto;
            padding: 12px 5px;
            height: 100%;
        }

        &__month-header {
            color: #ffffff;
            font-weight: 500;
            text-align: center;
            font-family: 'Frutiger-55';
            min-width: 120px;
            width: 100%;
            padding: 10px;
            font-size: 11px;
            border-radius: 5px 5px 0px 0px;
            border-top: 1px solid #32aacf;
            border-left: 1px solid #32aacf;
            border-right: 1px solid #32aacf;
            position: relative;

            &.active {
                border-top: 1px solid #e7b315;
                border-left: 1px solid #e7b315;
                border-right: 1px solid #e7b315;
            }

            &.disabled {
                opacity: 0.2;
            }

            &-button {
                width: 34px;
                height: 16px;
                background: #5991bc;
                border-radius: 7px;
                position: absolute;
                right: 0px;
                bottom: -20px;
                cursor: pointer;
                z-index: 2;
            }
        }
        &__redemption {
            display: flex;
            justify-content: space-between;
            padding: 6px;
            height: 150px;
            align-items: center;

            &-items-sale {
                display: flex;
                flex-direction: column;
                height: 100%;
                position: relative;
                margin: 0px 8px;

                &.disabled {
                    opacity: 0.2;
                }
                &-line {
                    width: 1px;
                    height: 30px;
                    border-left: solid 1px #ffffff;
                    margin-left: 37px;
                }
                &-number {
                    font-size: 10px;
                    color: white;
                    width: 24px;
                    height: 24px;
                    border-radius: 15px;
                    border: 1px solid white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 25px;
                    padding-left: 1px;
                    font-weight: 500;
                    letter-spacing: 0.5px;
                    background-color: #072056;
                    z-index: 1;
                }
                &-gout {
                    content: $svg_gout;
                    position: absolute;
                    top: -5px;
                    left: 26px;
                    width: 22px;
                }
                &-info {
                    width: 106px;
                    padding: 3px 0 0 6px;
                    height: 34px;
                    border-radius: 3px;
                    border: solid 1px #ffffff;
                    display: flex;
                    align-items: left;
                    justify-content: center;
                    flex-direction: column;
                    text-align: left;
                    font-family: 'Frutiger-55';
                    position: relative;

                    &-title {
                        font-size: 11px;
                        color: #88b929;
                        line-height: 11px;
                    }
                    &-circle {
                        content: $svg_contribution_tab;
                        position: absolute;
                        right: 0px;
                        top: 0px;
                    }
                    &-text {
                        font-size: 10px;
                        color: white;
                        line-height: 18px;
                    }
                }
            }
            &-items-purchase {
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: center;
                margin: 0px 8px;
                position: relative;

                &.disabled {
                    opacity: 0.2;
                }

                &-line {
                    width: 1px;
                    height: 80px;
                    border-left: solid 1px #ffffff;
                    margin-left: 37px;
                }
                &-number {
                    font-size: 0.75rem;
                    color: white;
                    width: 24px;
                    height: 24px;
                    border-radius: 15px;
                    border: 1px solid white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 25px;
                    padding-left: 1px;
                    background-color: #072056;
                    z-index: 1;
                }

                &-gout {
                    content: $svg_gout;
                    position: absolute;
                    top: -5px;
                    left: 26px;
                    width: 22px;
                }

                &-info {
                    width: 106px;
                    padding: 3px 0 0 6px;
                    height: 34px;
                    border-radius: 3px;
                    border: solid 1px #ffffff;
                    display: flex;
                    align-items: left;
                    justify-content: center;
                    flex-direction: column;
                    text-align: left;
                    font-family: 'Frutiger-55';

                    &-title {
                        font-size: 11px;
                        color: #a8a9e9;
                        line-height: 11px;
                    }
                    &-text {
                        font-size: 10px;
                        color: white;
                        line-height: 18px;
                    }
                }
            }
        }
    }

    .acnhor-account__items--date {
        font-size: 11px;
        color: $cool-blue;
        margin-top: -23px;
        margin-left: 37px;
    }
    &--title {
        font-size: 1.375rem;
        font-weight: 300;
        line-height: 1.09;
        color: #4a4a4a;
        margin-bottom: 13px;
        span {
            font-weight: 400;
        }
    }
    &--bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 75px;
        background-color: #ebf7fa;
        padding: 0px 20px;

        &-clear-filter {
            padding: 6px;
            width: 50px;
            cursor: pointer;
            margin-left: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-confirm {
            border: 1px solid #9199a1;
            padding: 6px;
            width: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50px;
            border-radius: 10px;
            cursor: pointer;
            margin-left: 12px;

            &.disabled {
                cursor: default;
            }

            &.active {
                cursor: pointer;
                border: 1px solid #32aacf;
                color: #32aacf;

                &:hover {
                    background-color: #32aacf;
                    color: #ffff;
                }
            }
        }

        &-contribution {
            width: 115px;
            height: 38px;
            border-radius: 2px;
            border: 1px solid #d1d1d1;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            font-weight: 500;
            color: #3f41d1;
            margin-top: 37px;
            cursor: pointer;
            background-color: #f5f4f4;
            border-radius: 4px 4px 0px 0px;
            border-bottom: none;
            border-right: none;
            font-family: 'Frutiger-55';

            &.active {
                background-color: #ffff;
                width: 145px;
                height: 51px;
                border: 1px solid #e3e3e3;
                border-bottom: none;
                margin-top: 24px;
                font-size: 16px;
            }
        }
        &-rescue {
            width: 115px;
            height: 38px;
            border-radius: 2px;
            border: 1px solid #d1d1d1;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            font-weight: 500;
            color: #88b929;
            margin-top: 37px;
            cursor: pointer;
            border-radius: 4px 4px 0px 0px;
            background-color: #f5f4f4;
            border-bottom: none;
            border-left: none;
            font-family: 'Frutiger-55';

            &.active {
                background-color: #ffff;
                width: 145px;
                height: 51px;
                border: 1px solid #e3e3e3;
                margin-top: 24px;
                border-bottom: none;
                font-size: 16px;
            }
        }
        &-search {
            margin-left: 15px;
            position: relative;
            width: 200px;
            height: 50px;
        }
        &-date {
            display: flex;
            justify-content: space-between;
            margin-left: 15px;
            cursor: pointer;
        }
    }
    &--table-head {
        margin-top: 20px;
        font-size: 12px;
        color: $cool-blue-two;
        display: flex;
        border-bottom: solid 2px $cool-blue-two;
        height: 30px;
        align-items: center;
        justify-content: space-between;
        padding-right: 18px;
        &-title {
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            .anchor-account__items--table-head-arrow {
                content: $svg_blue_arrow;
                margin-left: 5px;
            }
        }
        &-search {
            content: $svg_blue_search;
        }
    }
    &--table-cell {
        font-size: 0.875rem;
        color: $dark;
        display: flex;
        border-bottom: solid 1px #d0d0d0;
        height: 40px;
        align-items: center;
        justify-content: space-between;
        padding-right: 18px;
        font-weight: 300;
        line-height: 2.43;
        &-title {
            display: flex;
            justify-content: space-between;
        }
        &-pdf {
            font-size: 13px;
            color: $cool-blue;
            display: flex;
            cursor: pointer;
            .anchor-account__items--table-cell-pdf-icon {
                content: $svg_pdf_download;
                margin-right: 10px;
            }
        }
    }
}

.input-group-text {
    display: none;
}

.input-name {
    .react-date-picker__wrapper {
        width: 170px;
        height: 36px;
        border-radius: 6px;
        border: solid 1px #d0d0d0;
        background-color: #ffffff;
        padding: 0 10px;
    }
    .react-date-picker__calendar-button {
        content: $svg_blue_calendar;
    }
    .calendar-img {
        content: $svg_blue_calendar;
        width: 18px;
        height: 18px;
    }
    .react-date-picker__inputGroup {
        font-size: 14px;
        color: #a4a4a4;
        div {
            margin-top: 7px;
        }
    }
}

.calendar-name {
    border-radius: 6px;
    border-color: #d0d0d0;
    overflow: hidden;
    font-family: Frutiger;
    .react-calendar__navigation__label {
        color: #072056;
    }
    .react-calendar__month-view__weekdays {
        font-family: Frutiger;
        font-size: 0.8125rem;
        color: #3aadd1;
    }
    .react-calendar__month-view__days {
        font-size: 0.8125rem;
        font-weight: 300;
        color: #072056;
    }
    .react-calendar__tile--now,
    .react-calendar__tile--now:hover {
        background: none;
        color: #3aadd1;
    }
    .react-calendar__tile--active {
        background: #3aadd1;
        color: white;
    }
    .react-calendar__month-view__days__day--weekend {
        color: #c1c1c1;
    }
}

.account-scroll {
    &__shadow {
        background: linear-gradient(90deg, #082056 0%, transparent 2%, transparent 98%, #082056 100%);
        margin-top: -212px;
        height: 197px;
        position: relative;
        margin-bottom: 15px;
        z-index: 1;
    }
}
