@use "~styles/constants" as *;

.account-profile__items {
    font-family: Frutiger;

    .personal-data__items {
        padding-top: 110px;
        display: flex;
        justify-content: center;
        &--back {
            height: 45px;
            border-bottom: solid 1px #e3e3e3;
            border-right: solid 1px #e3e3e3;
            border-left: solid 1px #e3e3e3;
            position: fixed;
            background: white;
            top: 74px;
            left: 0;
            right: 0;
            z-index: 10;
            overflow: hidden;
            display: flex;
            flex-wrap: nowrap;
            .license-name__items {
                &--cancel {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    font-weight: 500;
                    cursor: pointer;
                    height: 46px;
                    font-size: 0.75rem;
                    letter-spacing: 0.8px;
                    color: $brown-grey;
                    border-left: solid 1px #e3e3e3;
                    padding-left: 15px;
                }
                &--save {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    font-weight: 500;
                    background: #30ab64;
                    color: white;
                    cursor: pointer;
                    height: 46px;
                    font-size: 0.75rem;
                    &.disabled {
                        background: #309964;
                        opacity: 0.9;
                        cursor: not-allowed !important;
                    }
                    &.disabled:hover {
                        opacity: 1;
                    }
                }
                &--edit {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    font-weight: 500;
                    background: #32aacf;
                    color: white;
                    cursor: pointer;
                    height: 46px;
                    font-size: 0.75rem;
                }
            }

            &__left {
                padding-right: 0;
            }

            &__right {
                padding-left: 0;
            }
        }
        &--sidebar {
            position: fixed;
            top: 170px;
        }
        &--sidebar-title {
            font-size: 0.875rem;
            font-weight: bold;
            color: $dark;
            padding: 10px;
        }
        &--sidebar-subtitle {
            font-size: 0.875rem;
            font-weight: 300;
            color: $cool-blue;
            padding: 10px;
            cursor: pointer;
            border-bottom: solid 1px #e3e3e3;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .personal-data__items--sidebar-icon {
                display: none;
                width: 7px;
                height: 7px;
                position: relative;
                border-top: solid 1px $marine;
                border-left: solid 1px $marine;
                transform: rotate(135deg);
                cursor: pointer;
            }
        }
        &--sidebar-subtitle.active {
            border-radius: 2px;
            background-color: rgba(50, 170, 207, 0.1);
            font-weight: 400;
            color: $dark;
            .personal-data__items--sidebar-icon {
                display: flex;
            }
        }
        &--title {
            font-size: 1.5rem;
            font-weight: 300;
            color: #3f41d1;
            border-bottom: solid 1px #3f41d1;
            margin-bottom: 7px;
        }
        &--title-two {
            font-size: 14px;
            font-weight: bold;
            color: $dark;
        }
        &--component {
            height: 44px;
            border-bottom: solid 1px #e3e3e3;
            display: flex;
            flex-direction: column;
            justify-content: center;
            &-button {
                font-size: 0.7813rem;
                color: white;
                font-weight: 500;
                border-radius: 5px;
                background-color: #3f41d1;
                width: 250px;
                height: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 15px;
                cursor: pointer;
            }
            &-second {
                width: 487px;
                height: 56px;
                border-radius: 6px;
                border: solid 1px #aaaaaa;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-left: 20px;
                margin-top: 20px;
            }
            &-third {
                width: 487px;
                height: 56px;
                border-radius: 6px;
                border: solid 1px #e8ebec;
                background-color: #f6f6f6;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-left: 20px;
                margin-top: 20px;
                .personal-data__items--text {
                    font-size: 16px;
                    color: #9199a1;
                }
            }
        }
        &--title-second {
            font-size: 0.75rem;
            color: #7b7b7b;
            margin-bottom: -5px;
        }
        &--text {
            font-size: 16px;
            color: $dark;
        }
        &--title-second {
            font-size: 0.75rem;
            color: #7b7b7b;
            margin-bottom: -2px;
        }
        &--text {
            font-size: 16px;
            color: $dark;
        }
        &--card {
            margin-bottom: 60px;
            margin-top: 30px;
            height: 60px;
            border-radius: 4px;
            background-color: $white_two;
            box-shadow: 0 2px 4px 0 $light-periwinkle;
            border: solid 1px #d9cc18;
            display: flex;
            position: fixed;
            bottom: 0;
            z-index: 5;
            &-block {
                display: flex;
                width: max-content;
                align-items: center;
                margin: 10px 0;
            }
            &-positive {
                content: $svg_positivo;
                width: 41px;
                height: 41px;
                margin-left: 60px;
            }
            &-error {
                content: $svg_error;
                width: 41px;
                height: 41px;
                margin-left: 60px;
            }
            &-close {
                content: $svg_fechar;
                width: 24px;
                height: 24px;
                margin-right: 30px;
                cursor: pointer;
            }
            &-text {
                font-size: 1.3rem;
                line-height: 1.3rem;
                color: $greyish-brown;
                width: 100%;
                padding: 0 60px 0 30px;
                display: block;
                span {
                    font-weight: 500;
                }
            }
        }

        &--container {
            margin-top: 50px;

            &.data {
                margin-left: -62px;
                padding-left: 78px;
            }
        }
    }
}
