@use "~styles/constants" as *;

.login--logo {
    content: $svg_logo_white;
    position: absolute;
    top: 40px;
    left: 50px;
    z-index: 1;
}

.login--title {
    color: $greyish_brown;
    font-size: 0.875rem;
    font-family: 'Frutiger';
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    p {
        margin: 0px;
    }
}

.login--link {
    text-decoration: none;
    font-family: 'Frutiger';
    text-align: right;
    font-size: 0.75rem;
    letter-spacing: 0.5px;
    font-weight: 500;
    cursor: pointer;
}

.login--container {
    .login--buttons {
        display: flex;
        justify-content: space-between;

        .button--enter {
            background: #3f41d1;
            width: 146px;
            height: 46px;
            border: none;
            border-radius: 10px;
            font-family: 'Frutiger';
            font-weight: 500;
            font-size: 0.875rem;
            letter-spacing: 1.5px;
        }

        .button--enter:hover {
            background: #32348d;
        }

        .button--register {
            background: none;
            width: 165px;
            height: 55px;
            border: none;
            font-family: 'Frutiger';
            font-weight: 500;
            font-size: 0.875rem;
            letter-spacing: 1.5px;
            color: #072056;
        }
    }
}
