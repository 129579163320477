@use "~styles/constants" as *;

.wallet__items {
    padding: 110px 0 0 0;
    font-family: Frutiger;
    background: #ffffff;

    &--title {
        font-family: 'Frutiger-55';
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 400;
        color: #072056;
        font-size: 34px;
        line-height: 24px;
        font-weight: 500;
        padding-bottom: 20px;
    }

    &--first-row {
        margin-top: 15px;
    }

    &--second-row {
        margin-top: 15px;
        margin-bottom: 45px;
    }

    &--switch-container {
        display: flex;
        justify-content: flex-end;
    }

    &--card {
        border-radius: 6px;
        box-shadow: 0 2px 2px 0 $light-periwinkle;
        border: solid 1px #e3e3e3;
        background-color: #ffffff;
        padding: 3px 0px 10px 3px;

        .card-container {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            padding: 15px 40px 0px 20px;

            &--child {
                display: flex;
                flex-direction: column;
                text-align: right;

                &__title {
                    font-size: 0.625rem;
                }

                &__value {
                    font-size: 1rem;
                    color: #3f41d1;
                }
            }
        }

        .share-card-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 10%;

            &--child {
                display: flex;
                align-items: baseline;
            }

            &--hr {
                margin-trim: 15px;
                margin-bottom: 20px;
            }
        }

        .infos-container {
            display: flex;
            justify-content: space-around;

            &--child {
                width: min-content;
                display: flex;
                align-items: center;

                &__empty {
                    font-size: 13px;
                }
            }
        }

        .fund-container {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &--select-all {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                cursor: pointer;
            }

            &--child {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-radius: 8px;
                border: 1px solid #e3e3e3;
                border-left: 8px solid #64b5f7;
                padding: 25px;
                margin-top: 10px;
                min-height: 100px;

                &__fund {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    &-title {
                        font-size: 14px;
                        line-height: 12px;
                        color: #072056;
                        width: 84%;
                        display: flex;
                        justify-content: space-between;

                        &--name {
                            width: 100%;
                            margin-right: 15px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        > div {
                            font-size: 14px;
                            color: #072056;
                        }
                    }

                    &-content {
                        font-size: 12px;
                        color: #4a4a4a;
                        margin-top: 15px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        gap: 5px;

                        &--container {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            gap: 10px;
                        }

                        &--row {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            flex: 1;
                            width: 80%;
                        }

                        &--cell {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            width: 25%;
                        }

                        &--dashed {
                            flex: 1;
                            overflow: hidden;
                            white-space: nowrap;
                        }

                        &--amount {
                            width: 25%;
                            display: flex;
                            justify-content: flex-end;
                        }
                    }
                }
            }

            &--all-funds {
                font-size: 13px;
                color: #32aacf;
                display: flex;
                justify-content: flex-end;
                margin-top: 10px;
                padding-right: 5px;

                &:hover {
                    color: #2989aa;
                }
            }
        }

        .history-container {
            width: 100%;
            height: 300px;
            padding: 0 20px;
        }

        .items--card-title {
            font-size: 18px;
            line-height: 1.33;
            color: $marine;
        }

        .history-items--card__title {
            font-size: 18px;
            line-height: 1.33;
            color: $marine;
            padding: 15px;
        }

        .items--card-title-second {
            font-size: 1.125rem;
            line-height: 1.33;
            color: $marine;
            padding: 0 20px;
        }

        .items--card-graph {
            width: 217px;
            height: 217px;
            background-color: #5991bc;
            border-radius: 50%;
        }

        .items--card-subtitle {
            width: 10px;
            height: 10px;
            background-color: #64b5f7;
            margin-right: 5px;

            &--container {
                width: calc(100% - 15px);
            }

            &--name {
                font-size: 0.75rem;
                line-height: 12px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            &--share {
                font-size: 1.25rem;
            }
        }

        hr {
            margin: 0;
        }
    }

    &--icon {
        content: $svg_next_hover;
        margin-left: 5px;
        width: 5px;
    }

    &--button {
        font-size: 9px;
        font-weight: 600;
        color: white;
        width: 70px;
        height: 20px;
        border-radius: 5px;
        background-color: #3f41d1;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
            background-color: $blurple_focus;
        }
    }
}
