@use "~styles/constants" as *;

.body__forgot-password {
    margin-top: 60px;
    flex-direction: column;
    align-items: center;
    font-family: 'Frutiger';
    @include responsive (s) {
        margin-top: 10px;
    }

    .text__field.text__has-value .MuiFilledInput-root,
    .text__field .MuiFilledInput-root.Mui-focused {
        border-color: $brown_grey_two;
    }

    .text__field .MuiFormHelperText-root.Mui-error {
        border-left: solid 1px $brown_grey_two;
        border-right: solid 1px $brown_grey_two;
        margin: 0px;
        padding: 5px 12px 0 12px;
    }

    .MuiFilledInput-root {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    .body__password--buttons {
        display: flex;
        flex-wrap: initial;
        margin-bottom: 30px;
        @include responsive (s){
            padding-top: 10px;
            margin-bottom: 5px;
            flex-wrap: nowrap;
        }
        @include responsive (ss){
            padding-top: 10px;
            margin-bottom: -14px;
            flex-wrap: nowrap;
        }
    }

    &--title {
        margin: auto;
        font-size: 1.375rem;
        text-align: center;
        margin-bottom: 48px;
        line-height: 1.3;
        @include responsive (s) {
            margin-bottom: 15px;
        }

        span {
            font-weight: 500;
        }
    }

    .form__style {
        @include responsive (s) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .forgot-password--text {
        color: $greyish_brown;
        border: solid 1px $brown_grey_two;
        border-top: none;
        background-color: $white_two;
        border-radius: 0px 0px 6px 6px;
        font-size: 0.875rem;
        padding: 12px 25px 12px 10px;
        font-family: 'Frutiger';
        letter-spacing: 0.5px;
        line-height: 1.3;
        margin-bottom: 50px;
        @include responsive (s) {
            margin-bottom: 10px;
        }
    }

    .forgot-password--button {
        margin-left: 12px;
        border: unset;
        width: 173px;
        height: 46px;
        border-radius: 10px;
        background: #3f41d1;
        color: white;
        outline: none;
        @include responsive (s) {
            margin-bottom: 86px;
            margin-top: 20px;
        }
    }

    .forgot-password--button:hover {
        background: #3f41d1;
    }
}
