@use "~styles/constants" as *;

.anchor-capture__items {
    padding: 30px 0 70px;
    font-family: Frutiger;
    background: white;
    // height: 100vh;
    &--card {
        border-radius: 6px;
        box-shadow: 0 2px 2px 0 $light-periwinkle;
        border: solid 1px #e3e3e3;
        background-color: #ffffff;

        .items--card-block {
            padding-bottom: 15px;
        }
        .items--card-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px;
            &-title {
                font-size: 1.125rem;
                line-height: 1.33;
                color: $marine;
            }
            &-buttons {
                display: flex;
                align-items: center;
                > button {
                    border-radius: 4px;
                    background-color: #ffffff;
                    cursor: pointer;
                    font-size: 0.625rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    outline: 0;
                }
                :not(:first-child) {
                    margin-left: 7px;
                }
                &-active {
                    border: solid 1px $cool-blue;
                    color: $cool-blue;
                    padding: 4px 13px 2.5px;
                }
                &-inactive {
                    border: solid 1px $very-light-pink;
                    color: $brown-grey;
                    padding: 4px 13px 2.5px;
                }
            }
        }
        .items--card-graph {
            padding: 0 20px 20px 0px;
            box-sizing: border-box;
            &-title {
                display: flex;
                justify-content: space-between;
                width: 100%;
                padding-left: 20px;
                padding-bottom: 10px;
                > div {
                    color: black;
                    display: flex;
                    align-items: center;
                    width: max-content;
                }
                &-one {
                    padding-left: 4%;
                    justify-content: right;
                    font-size: 0.675rem;
                    width: 15.5%;
                }
                &-two {
                    justify-content: right;
                    font-size: 0.75rem;
                }
                &-three {
                    justify-content: left;
                    font-size: 0.675rem;
                }
                &-info {
                    color: $sky-blue;
                    margin-left: 5px;
                    font-weight: bold;
                    font-size: 0.875rem;
                }
            }
        }
        // .items--card-footer {
        //     padding: 0 20px 20px 20px;
        //     display: flex;
        //     justify-content: space-around;
        //     &-subtitle {
        //         display: flex;
        //         justify-content: center;
        //         align-items: center;
        //         width: 100%;
        //         &-legend {
        //             display: flex;
        //             flex-grow: 1;
        //             justify-content: center;
        //             > div:first-child {
        //                 margin-right: 15px;
        //             }
        //         }
        //         &-button {
        //             justify-content: flex-end;
        //         }
        //         &-item {
        //             display: flex;
        //             align-items: center;
        //             justify-content: center;
        //             &-color {
        //                 width: 10px;
        //                 height: 10px;
        //             }
        //             &-text {
        //                 margin-left: 5px;
        //                 font-size: 0.6875rem;
        //                 color: #4a4a4a;
        //             }
        //         }
        //     }
        // }
        .items--card-selector {
            position: relative;
            .items--card-buttons-third {
                &-open {
                    font-size: 0.75rem;
                    color: $greyish-brown;
                    border: solid 1px $very-light-pink;
                    background-color: #ffffff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    margin: 0;
                    padding: 2px 13px;
                    border-radius: 4px 4px 0 0;
                }
                &-closed {
                    font-size: 0.75rem;
                    color: $greyish-brown;
                    border: solid 1px $very-light-pink;
                    background-color: #ffffff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    margin: 0;
                    padding: 2px 13px;
                    border-radius: 4px;
                }
                &-arrow {
                    width: 8px;
                    height: 8px;
                    border-top: solid 1px $cool-blue-two;
                    border-left: solid 1px $cool-blue-two;
                    transform: rotate(225deg);
                    margin-left: 10px;
                    margin-top: -5px;
                }
            }
            .items--card-selector-options {
                background-color: #fff;
                border: 1px solid $very-light-pink;
                border-top: none;
                border-radius: 0 0 4px 4px;
                display: flex;
                flex-direction: column;
                margin: 0;
                position: absolute;
                top: 100%;
                width: 100%;
                z-index: 1;
                .items--card-selector-option {
                    display: flex;
                    font-size: 12px;
                    padding: 3px 7px;
                    margin: 0;
                    min-width: max-content;
                }
                .items--card-selector-option:hover {
                    background-color: #e3e3e3;
                }
            }
        }
        .tooltip--block {
            min-width: max-content;
            hr {
                margin: 5px 0;
            }
            &-two {
                display: flex;
                flex-direction: column;
            }
            &-line {
                display: flex;
                align-items: center;
                width: max-content;
            }
            &-color {
                display: inline-block;
                height: 10px;
                margin-right: 5px;
                width: 10px;
            }
            &-text {
                font-size: 10px;
                text-transform: capitalize;
                &-two {
                    margin-left: 15px;
                }
            }
        }
        .capital-evolution-twelve-months .legend,
        .capture-balance .legend {
            margin-top: 5px;
        }

        .capture-volume,
        .capture-balance {
            .tooltip--block-text {
                text-transform: uppercase;
            }
        }

        .tick {
            font-weight: 300;
        }
    }
}
