@use 'styles/constants/colors' as *;
@use 'styles/constants/images' as *;

.toastify {
    &-container {
        padding: 0;
    }
    &-toast {
        background-color: transparent;
        box-shadow: none;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        min-width: 420px;
        width: 100%;
        padding: 0px 15px;
        background: white;
        border-radius: 4px;
        border: 1px solid #32aacf;
        position: relative;

        .button__style--close {
            position: absolute;
            top: 24%;
            right: 5%;
        }

        &.Toastify__toast--success {
            border: 1px solid #d9cc18;

            .Toastify__toast-body {
                &::before {
                    content: $svg_positivo_2;
                    width: 58px;
                    height: 40px;
                    padding-right: 10px;
                }
            }
        }
        &.Toastify__toast--error {
            border: 1px solid #db6968;

            .Toastify__toast-body {
                &::before {
                    content: $svg_error;
                    width: 55px;
                    height: 35px;
                    padding-right: 10px;
                }
            }
        }
        .Toastify__toast-body {
            position: relative;
            display: flex;
            align-items: center;
            font-family: 'Frutiger';
            font-size: 17px;
            color: $scorpion;
            text-align: left;
            padding-right: 40px;

            .Toastify__toast-icon {
                width: 35px !important;
            }
        }
    }
}
