@use "~styles/constants" as *;

.button__style--second {
    .MuiButton-contained {
        background-color: #3f41d1;
        box-shadow: unset;
    }

    .MuiButton-contained:hover {
        background-color: #32348d;
        box-shadow: unset;
    }

    .MuiButton-label {
        justify-content: center;
    }

    .MuiButton-contained:hover,
    .MuiButton-contained {
        color: #fff;
        font-size: 0.875rem;
        font-family: 'Frutiger';
        font-weight: 400;
        letter-spacing: 1.5px;
        border-radius: 10px;
        width: 100%;
        max-width: 172px;
        min-width: 172px;
        min-height: 46px;
        max-height: 46px;
        @include responsive(s) {
            max-width: 130px;
            min-width: 130px;
        }
    }

    .button--arrow {
        content: $svg_next;
        padding-left: 5px;
        @include responsive(s) {
            transform: rotate(90deg);
            padding-left: 0;
            padding-bottom: 5px;
        }
    }

    .MuiButton-contained.Mui-disabled {
        background: rgba(63, 65, 209, 0.3);
        cursor: not-allowed;

        span {
            color: white;
        }
    }

    .sc-bwzfXH.sc-EHOje.hkQVFp,
    .sc-bwzfXH.sc-htpNat.flDhnP,
    .sc-bwzfXH.sc-bxivhb.kINVDU,
    .sc-bwzfXH.sc-ifAKCX.gJwEBr {
        .sc-bZQynM.iepmoa {
            background: white;
            width: 5px;
            height: 5px;
        }
    }

    .gfLNMF {
        width: 10px;
        height: 10px;
     }
}
