@use "~styles/constants" as *;

.terms-of-use__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Frutiger';
    width: 100%;
    font-size: 0.875rem;
    font-weight: 300;

    h3 {
        font-size: 1rem;
        margin-bottom: 25px;
    }
}
