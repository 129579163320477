@use "~styles/constants" as *;

.text__field {
    font-family: Frutiger;

    .MuiFormControl-root {
        border: solid 1px #aaaaaa;
    }
    .MuiFilledInput-root {
        border: solid 1px #aaaaaa;
        border-radius: 6px;
        background-color: #ffffff;
        color: #000000;
        .MuiFilledInput-input {
            padding: 23px 27px 10px 21px;
        }
    }
    &.text__has-value .MuiFilledInput-root,
    .MuiFilledInput-root:hover {
        background: $white_three;
        color: $bluey_grey;
    }
    &.text__has-value .MuiFilledInput-root,
    .MuiFilledInput-root.Mui-focused {
        background: $white_two;
        border: solid 1px $brown_grey_two;
        color: $black;
    }
    .MuiFilledInput-underline:after {
        border-bottom: transparent;
        top: 0;
        bottom: unset;
        margin: auto;
        width: 99%;
    }
    .MuiFilledInput-underline:before {
        display: none;
    }
    &.hide-underline {
        .MuiFilledInput-underline {
            &:before,
            &:after {
                display: none !important;
            }
        }
    }
    .MuiFormLabel-root,
    .MuiFormLabel-root.Mui-focused {
        color: $brown_grey;
    }
    .MuiSvgIcon-root {
        color: $gross_green;
    }
    .MuiFormHelperText-root.Mui-error {
        font-size: 0.813rem;
        font-family: 'Frutiger';
    }
}

.text__field .MuiFormLabel-root,
.text__field .MuiFormLabel-root.Mui-focused {
    padding: 0 0 0 14px!important;
}