@use "~styles/constants" as *;

.fund-documents__items {
    padding: 30px 0 70px;
    font-family: Frutiger;
    background: white;
}

.fund-documents {
    padding: 10px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__container {
        max-width: 1140px;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        padding: 20px 0;
        width: 100%;

        &--title {
            font-size: 34px;
            font-weight: 700;
            color: #545454;
            margin: 0;

            @include responsive(sm) {
                text-align: center;
                font-size: 32px;
            }

            @include responsive(s) {
                text-align: center;
                font-size: 32px;
                margin-bottom: 20px;
            }

            @include responsive(ss) {
                text-align: center;
                font-size: 29px;
            }
        }

        @include responsive(s) {
            flex-direction: column;
        }
    }

    &__table {
        @include responsive(s) {
            width: 100%;
            overflow-x: auto;
        }

        .page-container--table {
            &--not-found {
                height: 40vh;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                &--icon {
                    margin-bottom: 20px;
                    content: $svg_document_not_found;
                }
                h1 {
                    font-family: 'Frutiger-55';
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 24px;
                    color: #d8d8d8;
                }
                h4 {
                    font-family: 'Frutiger-55';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: #d8d8d8;
                }
            }
            th {
                &:nth-child(1) {
                    width: 40%;
                }
                &:nth-child(2) {
                    width: 27%;
                }
                &:nth-child(3) {
                    width: 15%;
                }
            }
            td {
                &:nth-child(1) {
                    width: 40%;
                }
                &:nth-child(2) {
                    width: 27%;
                }
                &:nth-child(3) {
                    width: 28%;
                }
                &:nth-child(4) {
                    width: 22px;
                    content: $svg_download;
                    height: 20px;
                }
            }
            @include responsive(sm) {
                th {
                    &:nth-child(1) {
                        width: 48%;
                    }
                    &:nth-child(2) {
                        width: 22%;
                    }
                    &:nth-child(3) {
                        width: 15%;
                    }
                }
                td {
                    &:nth-child(1) {
                        width: 310%;
                    }
                    &:nth-child(2) {
                        width: 145%;
                    }
                    &:nth-child(3) {
                        width: 10%;
                    }
                    &:nth-child(3) {
                        width: 100%;
                    }
                }
            }

            @include responsive(s) {
                th {
                    &:nth-child(1) {
                        width: 48%;
                    }
                    &:nth-child(2) {
                        width: 22%;
                    }
                    &:nth-child(3) {
                        width: 15%;
                    }
                }
                td {
                    &:nth-child(1) {
                        width: 310%;
                    }
                    &:nth-child(2) {
                        width: 145%;
                    }
                    &:nth-child(3) {
                        width: 10%;
                    }
                    &:nth-child(3) {
                        width: 100%;
                    }
                }
            }
        }

        &--content {
            min-height: 330px;
        }

        &--cell-icon {
            @include responsive(sm) {
                width: 7%;
            }

            @include responsive(s) {
                width: 7%;
            }
        }
    }
}
