@use "~styles/constants" as *;

.anchor-performance__items {
    padding-top: 30px;
    font-family: Frutiger;
    // height: 100vh;

    &--loading {
        .gfLNMF {
            height: 30px;
            width: 30px;
            .iepmoa {
                height: 9px;
                width: 9px;
                background-color: $organization_primary;
            }
        }
    }

    &--error {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 80vh;
        flex-direction: column;

        &-message {
            font-family: 'Catamaran', sans-serif;
            font-size: 1.25rem;
            color: $scorpion;
            text-align: center;
            line-height: 20px;
            margin-bottom: 30px;
        }

        .button__style--action .MuiButton-contained:hover,
        .button__style--action .MuiButton-contained {
            max-width: 220px;
        }
    }

    &--title {
        font-size: 1.375rem;
        font-weight: 300;
        line-height: 1.09;
        color: #4a4a4a;
        margin-bottom: 13px;
        span {
            font-weight: 400;
        }
    }
    &--card-third {
        background-color: #ffffff;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border-top: solid 1px #e3e3e3;
        border-left: solid 1px #e3e3e3;
        border-right: solid 1px #e3e3e3;
        .anchor-performance__items--card-third-title-img {
            content: $svg_blue_arrow_cicle;
            width: 28px;
            height: 28px;
            cursor: pointer;
        }
        &-title {
            font-size: 1.125rem;
            line-height: 1.33;
            color: $marine;
            padding: 15px 35px 15px 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    &--card {
        box-shadow: 0 2px 2px 0 $light-periwinkle;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        border-bottom: solid 1px #e3e3e3;
        border-left: solid 1px #e3e3e3;
        border-right: solid 1px #e3e3e3;
        background-color: #ffffff;
        max-height: 500px;
        overflow: auto;
        margin: 20px 0;

        .items--card-title {
            font-size: 1.125rem;
            line-height: 1.33;
            color: $marine;
            padding: 0px 20px 0px 20px;
        }
        .items--card-title-second {
            font-size: 1.125rem;
            line-height: 1.33;
            color: $marine;
            padding: 0 20px;
        }
        hr {
            margin: 0;
        }
        &-cell {
            padding: 8px 25px 8px 20px;
            border-bottom: solid 1px #e3e3e3;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            &-title {
                font-size: 0.875rem;
                font-weight: 300;
                line-height: 1.14;
                color: $cool-blue;
            }
            &-text {
                font-size: 12px;
                color: $dark;
            }
            &-arrow {
                display: none;
                content: $svg_green_arrow;
            }
        }
        &-cell.active {
            border: solid 1px $gross-green;
            .anchor-performance__items--card-cell-title {
                color: $dark;
                font-weight: 400;
            }
            .anchor-performance__items--card-cell-arrow {
                display: flex;
            }
        }
    }
    .items--card-buttons {
        &-first {
            font-size: 10px;
            color: $brown-grey;
            border-radius: 4px;
            border: solid 1px $very-light-pink;
            background-color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            padding: 3px 13px 1px 13px;
        }
        &-second {
            font-size: 10px;
            color: $cool-blue;
            border-radius: 4px;
            border: solid 1px $cool-blue;
            background-color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            padding: 3px 13px 1px 13px;
            margin-left: 7px;
        }
    }
    .items--card-buttons-four {
        width: 99px;
        height: 25px;
        border-radius: 5px;
        background-color: $blurple;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        span {
            font-family: Frutiger;
            font-size: 0.7813rem;
            color: white;
        }
    }
    &--card-second {
        height: 500px;
        padding-bottom: 50px;
        &-title {
            font-size: 1.125rem;
            line-height: 1.33;
            color: $marine;
            display: flex;
            padding: 20px 20px 0 0px;
            align-items: center;
            .items--card-second-title-part-two {
                font-weight: 300;
                color: $dark;
                > span:first-child {
                    font-size: 14px;
                    margin: 3px 5px 0;
                    margin-left: 10px;
                    display: flex;
                    align-items: center;
                    span {
                        font-size: 12px;
                        margin: 2.5px 5px 0;
                    }
                }
            }
        }
        &-button {
            font-size: 0.625rem;
            line-height: 4px;
            color: $cool-blue;
            padding: 5px;
            border-radius: 4px;
            border: solid 1px $cool-blue;
            cursor: pointer;
            margin-right: 20px;
            width: 112px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &-img {
            content: $svg_bt_expandir;
            cursor: pointer;
        }
        &-chart {
            display: flex;
            justify-content: space-around;
            padding: 45px 20px 0 20px;
            .anchor-risk__items--chart {
                width: 2.9%;
                height: 40px;
                background: $sky-blue;
                cursor: pointer;
            }
        }
        &-border {
            border: solid 1px #b9b9b9;
            margin-left: 27px;
            margin-right: 27px;
        }
        // &-subtitle {
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     padding: 15px 0 30px 0;
        // }
        &-table {
            .card-second-table--title {
                font-size: 0.75rem;
                color: $cool-blue-two;
                display: flex;
                justify-content: space-between;
                padding: 5px 0;
                &-icon {
                    content: $svg_table_arrow;
                    margin-left: 6px;
                }
                &-img {
                    content: $svg_shape;
                }
            }
            .card-second-table--items {
                &-cell {
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 2.43;
                    color: $dark;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: solid 1px #d0d0d0;
                    padding-right: 100px;
                    &-tag {
                        width: 54px;
                        height: 15px;
                        background-color: #fae9b7;
                        font-size: 0.625rem;
                        color: $greyish-brown;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
                &-cell:hover {
                    background: $pale-grey;
                }
            }
        }
        &-border-two {
            border-bottom: solid 2px $cool-blue-two;
        }
        .items--card-graph {
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding-top: 20px;
            margin-bottom: 25px;
            &-title {
                display: flex;
                justify-content: space-between;
                width: 100%;
                padding-left: 20px;
                padding-bottom: 10px;
                > div {
                    color: black;
                    display: flex;
                    align-items: center;
                    width: max-content;
                }
                &-three {
                    justify-content: left;
                    font-size: 0.675rem;
                }
            }
        }
    }

    .search-text-field {
        height: 36px;
        border-radius: 6px;
        border: solid 1px #d0d0d0;
        display: flex;
        align-items: center;
        background-color: #ffffff;
        .MuiFormControl-fullWidth {
            height: 100%;
            display: flex;
            align-items: center;
        }
        .MuiFilledInput-root {
            background-color: #ffffff;
            height: 100%;
            border-radius: 4px;
            &::before {
                display: none;
            }
        }

        .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
            margin-top: 0;
        }
        #search-field {
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0 20px;
            cursor: pointer;
        }
    }

    .items--card-block {
        padding-bottom: 15px;
    }
    .items--card-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        &-title {
            font-size: 1.125rem;
            line-height: 1.33;
            color: $marine;
        }
        &-buttons {
            display: flex;
            align-items: center;
            > div {
                border-radius: 4px;
                background-color: #ffffff;
                cursor: pointer;
                display: flex;
                font-size: 0.625rem;
                align-items: center;
                justify-content: center;
                padding: 3px 13px 1px 13px;
            }
            :not(:first-child) {
                margin-left: 7px;
            }
            &-active {
                border: solid 1px $cool-blue;
                color: $cool-blue;
            }
            &-inactive {
                border: solid 1px $very-light-pink;
                color: $brown-grey;
            }
        }
    }
    .items--card-graph {
        padding-right: 30px;
        box-sizing: border-box;
        &-title {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding-left: 20px;
            padding-bottom: 10px;
            > div {
                color: black;
                display: flex;
                align-items: center;
                width: max-content;
            }
            &-one {
                padding-left: 4%;
                justify-content: right;
                font-size: 0.675rem;
                width: 15.5%;
            }
            &-two {
                justify-content: right;
                font-size: 0.75rem;
            }
            &-three {
                justify-content: left;
                font-size: 0.675rem;
            }
            &-info {
                color: $sky-blue;
                margin-left: 5px;
                font-weight: bold;
                font-size: 0.875rem;
            }
        }
        .legend {
            margin-left: 20px;
        }
    }
    .items--card-footer {
        padding: 0 20px 0;
        display: flex;
        justify-content: center;
        &-subtitle {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            &-legend {
                display: flex;
                flex-grow: 1;
                justify-content: center;
                &-one {
                    display: flex;
                    align-items: center;
                    justify-content: right;
                    width: 70%;
                }
                &-two {
                    display: flex;
                    align-items: center;
                    margin-left: 15px;
                    width: 30%;
                }
            }
            &-button {
                justify-content: flex-end;
            }
            &-item {
                display: flex;
                align-items: center;
                justify-content: center;
                &-color {
                    width: 10px;
                    height: 10px;
                }
                &-text {
                    margin-left: 5px;
                    font-size: 0.6875rem;
                    color: #4a4a4a;
                }
            }
        }
    }
    .items--card-selector {
        position: relative;
        .items--card-buttons-third {
            &-open {
                font-size: 0.75rem;
                color: $greyish-brown;
                border: solid 1px $very-light-pink;
                background-color: #ffffff;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                margin: 0;
                padding: 2px 13px;
                border-radius: 4px 4px 0 0;
            }
            &-closed {
                font-size: 0.75rem;
                color: $greyish-brown;
                border: solid 1px $very-light-pink;
                background-color: #ffffff;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                margin: 0;
                padding: 2px 13px;
                border-radius: 4px;
            }
            &-arrow {
                width: 8px;
                height: 8px;
                border-top: solid 1px $cool-blue-two;
                border-left: solid 1px $cool-blue-two;
                transform: rotate(225deg);
                margin-left: 10px;
                margin-top: -5px;
            }
        }
        .items--card-selector-options {
            background-color: #fff;
            border: 1px solid $very-light-pink;
            border-top: none;
            border-radius: 0 0 4px 4px;
            display: flex;
            flex-direction: column;
            margin: 0;
            position: absolute;
            top: 100%;
            width: 100%;
            z-index: 1;
            .items--card-selector-option {
                display: flex;
                font-size: 12px;
                padding: 3px 7px;
                margin: 0;
                min-width: max-content;
            }
            .items--card-selector-option:hover {
                background-color: #e3e3e3;
            }
        }
    }
    .tooltip--block {
        min-width: max-content;
        hr {
            margin: 5px 0;
        }
        &-two {
            display: flex;
            flex-direction: column;
        }
        &-line {
            display: flex;
            align-items: center;
            width: max-content;
        }
        &-color {
            display: inline-block;
            height: 10px;
            margin-right: 5px;
            width: 10px;
        }
        &-text {
            font-size: 11px;
            text-transform: capitalize;
            &-two {
                margin-left: 15px;
            }
        }
    }
    .c3-axis-x .tick {
        text-transform: lowercase;
    }
    .c3-axis-y .tick {
        font-weight: 300;
    }

    .payment-beahvior {
        .c3-legend-item {
            display: none;
        }
    }

    .total-purchase-volume {
        svg > g:first-child {
            transform: translate(60.5, 4.5) !important;
        }
    }
    .c3-axis-y2 {
        display: none;
    }
}
