@use "~styles/constants" as *;

.container__footer {
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
    align-items: flex-end;
}

.footer--text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Raleway';
    font-size: 0.875rem;
    font-weight: 500;
    color: #9b9b9b;
    height: 50px;
    text-align: center;
    width: 100%;
    border: solid 1px $light-periwinkle;
    background: #ffffff;
}
