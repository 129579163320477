.empty-fund-warning {
    width: 100%;
    height: 260px;
    display: flex;
    justify-content: center;
    align-items: center;

    > span {
        line-height: 24px;
        color: #d8d8d8;
        font-weight: 300;
        font-size: 23px;
    }
}
