@use "~styles/constants" as *;

.income-statement__items {
    padding: 30px 0 70px;
    font-family: Frutiger;
    background: white;
}

.income-statement {
    padding: 10px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__container {
        max-width: 1140px;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }

    &__error-button {
        font-family: 'Raleway';
        font-size: 18px;
        color: #9b9b9b;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        gap: 10px;
        top: 50%;
        left: 40%;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        padding: 20px 0;
        width: 100%;

        &--title {
            font-size: 34px;
            font-weight: 700;
            color: #545454;
            margin: 0;

            @include responsive(sm) {
                text-align: center;
                font-size: 32px;
            }

            @include responsive(s) {
                text-align: center;
                font-size: 32px;
                margin-bottom: 20px;
            }

            @include responsive(ss) {
                text-align: center;
                font-size: 29px;
            }
        }

        @include responsive(s) {
            flex-direction: column;
        }
    }

    &__table {
        &--container {
            width: 100%;
            display: flex;
            padding: 10px 10px 10px 18px;
            align-items: center;
            justify-content: space-between;
        }

        &--title {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 30px;

            h3 {
                font-size: 20px;
                font-weight: 600;
                color: #545454;
                margin: 0;
            }
        }

        &--empty-years {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 35vh;
            margin: 0;
            font-family: 'Frutiger-55';
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #d8d8d8;
        }

        @include responsive(s) {
            width: 100%;
            overflow-x: auto;
        }

        .page-container--table {
            overflow: auto;
            display: block;
            padding-bottom: 5px;
            margin: 5px 0;
            max-height: calc(100vh - 350px);

            &.SECONDARY_ACCESS_ACTIVE {
                max-height: calc(100vh - 380px);
            }

            &::-webkit-scrollbar {
                height: 4px;
                width: 4px;
                background-color: #e1e2e6;
            }

            &::-webkit-scrollbar-thumb {
                height: 4px;
                width: 4px;
                background: #a4a4a4;
            }

            tr {
                display: flex;
                font-family: 'Frutiger-55';
                font-size: 15px;
                line-height: 25px;
                border-bottom: 1px solid #e6d8d8;
            }

            td {
                display: flex;
                font-family: 'Frutiger-55';
                border-right: 1px solid #e6d8d8;
                border-collapse: collapse;

                span {
                    display: inline-block;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }

            &__header {
                position: sticky;
                top: 0;
                z-index: 3;
                background-color: #fff;
            }

            &__header-items {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 156px;
            }

            &__row-items {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 156px;
                background-color: $ice-blue;
            }

            &__sticky {
                width: 330px;
                justify-content: flex-start;
                position: sticky;
                left: 0;
                z-index: 2;
                padding-right: 10px;
                border-left: 1px solid #e6d8d8;
            }

            .first-columns {
                background-color: #fff;
            }

            .none-border {
                border-left: none;
            }

            &-icon {
                &.COLLAPSED {
                    content: $svg_arrow;
                    margin-right: 10px;
                    max-width: 14px;
                }

                &.RETRACTED {
                    content: $svg_arrow;
                    transform: rotate(270deg);
                    margin-right: 10px;
                    max-width: 14px;
                }
            }

            .revenue {
                cursor: pointer;
                font-weight: 800;
                background-color: lightblue;
            }

            .operational {
                cursor: pointer;
                font-weight: 800;
                background-color: #fedbdb;
            }

            .operational-color {
                background-color: #fbeaea;
            }

            .pointer {
                cursor: pointer;
            }

            .debentures-series {
                background-color: #fff;
                justify-content: flex-start;
                padding-left: 30px;
            }

            .NEGATIVE_VALUES {
                color: #ff0000;
            }
        }

        &--content {
            min-height: 330px;
        }

        &--cell-icon {
            @include responsive(sm) {
                width: 7%;
            }

            @include responsive(s) {
                width: 7%;
            }
        }
    }
}
