@use '~styles/constants' as *;

.select-all-modal {
    width: 100vw;

    .modal-dialog {
        width: 825px !important;
        height: 535px;

        .modal-content {
            height: 100%;
        }
    }

    &--list {
        overflow-y: auto;
    }

    &--row {
        width: 100%;
        padding: 0 40px;
    }
}

.select-all-modal--container {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 25px;
    width: 100%;
}

.select-all-modal--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &-title {
        font-size: 18px;
        font-weight: 500;
        color: #072056;
        letter-spacing: 0.3px;
        line-height: 24px;
        margin: 0;
        flex: 1;
        text-align: center;
    }
}

.select-all-modal--items {
    width: 100%;
    height: 61px;
    border-radius: 8px;
    border: solid 1px #e3e3e3;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: auto;
    margin-bottom: 5px;

    &__chart {
        display: flex;
        align-items: baseline;

        &-bar {
            width: 4px;
            background-color: #5991bc;
            margin-left: 2px;
        }

        &-bar:nth-child(1) {
            height: 10px;
        }

        &-bar:nth-child(2) {
            height: 13px;
        }

        &-bar:nth-child(3) {
            height: 16px;
        }

        &-bar:nth-child(4) {
            height: 18px;
        }
    }

    &__series {
        display: flex;
        justify-content: center;
        width: 20%;
        font-family: Frutiger;
        font-size: 14px;
        font-weight: 500;
        color: $greyish-brown;
        border-left: solid 1px #e3e3e3;
        border-right: solid 1px #e3e3e3;

        span {
            width: min-content;
            max-width: 90%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    &__fund {
        font-family: Frutiger;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.33;
        color: $greyish-brown;
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        width: 180px;

        span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    &__balance {
        font-family: Frutiger;
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        color: $greyish-brown;
        display: flex;
        flex-direction: column;
        gap: 3px;
        width: 180px;
        padding-left: 20px;

        &-title {
            font-size: 10px;
            font-weight: normal;
        }

        span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    &__button {
        .MuiButton-contained,
        .MuiButton-contained:hover {
            width: 80px;
            height: 25px;
            letter-spacing: unset;
            font-size: 10px;
            font-weight: 600;
            border-radius: 5px;
            padding: 0;
        }

        .MuiButton-label {
            margin-top: 2px;
        }
    }
}
