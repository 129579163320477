@use "~styles/constants" as *;

.tooltip-chart {
    font-family: Frutiger;
    font-size: 11px;
    opacity: 0.9;
    border-radius: 6.5px;
    border: solid 1px #b9b9b9;
    background-color: #ffffff;
    padding: 12px;
}
